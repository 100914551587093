import React, { useEffect, useState } from 'react'
import t from 'counterpart'
import { DataForm, Hint } from '../../libs/react-mpk/components'
import { toast, http } from '../../libs/react-mpk/services'
import produkService from '../Produk/Produk.service'
import transactionService from '../Transaction/Transaction.service'
import satuanProdukService from '../Produk/SatuanProduk.service'
import numeral from 'numeral'
import { find } from 'lodash'
import { TextField, Checkbox } from 'react-md'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import './OrderForm.scss'

const OrderForm = ({
    visible,
    onRequestClose,
    ...props
}) => {
    const [ready, setReady] = useState(false)
    const [produkList, setProdukList] = useState([])
    const [ amountTotal, setAmountTotal ] = useState(0)
    const [tipeOrder, setTipeOrder] = useState('PREPAID')
    const [balance, setBalance] = useState({balance: 0})
    // const [satuanProdukList, setSatuanProdukList] = useState([])

    // const handleFetchSatuanProduk = async(produkId) => {
    //     return new Promise(async (resolve, reject) => {
    //         try{   
    //             let res = await satuanProdukService.getByProductId(produkId, {size: 100})
    //             setSatuanProdukList(res.data)
    //             resolve(res.data)
    //         }catch(error){
    //             reject(error)
    //         }
    //     })
    // }

    useEffect(() => {
        let amount = produkList.filter(d => (d.checked)).reduce((a, b) => (a + b.harga*Number(b.qty)), 0)
        setAmountTotal(amount)
    }, [produkList])
    
    useEffect(async () => {
        try {
            let res = await produkService.getByClientId()
            if(res.data.length > 0) {
                let resBalance = await http.get(`/quota/${res.data[0].kode}/balance`)
                setTipeOrder(resBalance.data.limit > 0 ? 'POSTPAID' : 'PREPAID')
                setBalance(resBalance.data)
            }
            setProdukList(res.data.map(d => {
                d.checked = false
                d.qty = '0'
                return d
            }))
            setReady(true)
        } catch (error) {
            toast.errorRequest(error)
        }
    }, [])
    
    return ready && (
       <DataForm
            baseId="mod-buy-form"
            className="order-form"
            title={t.translate('column.buy')}
            dialogStyle={{
                width: '100%',
                maxWidth: 800
            }}
            asDialog={true}
            visible={visible}
            onRequestClose={() => {
                onRequestClose()
                setTimeout(() => {
                    setProdukList(list => {
                        list = list.map(d => {
                            d.qty = '0'
                            d.checked = false
                            return d
                        })
                        return [...list]
                    })
                }, 800)
                
            }}
            watchDefaultData={true}
            defaultData={{}}
            definitions={[
                {
                    render: (
                        <div className="mpk-full full-width">
                            {produkList.map((d, i) => (
                                <div 
                                    className="mpk-full full-width mpk-flex mpk-padding-N padding-top padding-bottom mpk-border border-bottom thin solid dark"
                                    key={d.id}
                                >
                                    <Checkbox
                                        id={`chekbox-${d.id}`}
                                        checked={d.checked}
                                        onChange={(e) => {
                                            setProdukList( list => {
                                                list[i].checked = e.target.checked
                                                list[i].qty = e.target.checked 
                                                    ? (tipeOrder === 'POSTPAID' ? balance.balance : 1)
                                                    : 0
                                                return [...list]
                                            })
                                        }}
                                    />
                                    <div className="flex">
                                        <div className="mpk-font size-M weight-B">{d.nama}</div>
                                        <div className="mpk-margin-N margin-bottom mpk-font size-NS">Rp. {`${numeral(d.harga).format('0,0')}${d.satuan ? `/${d.satuan.nama}` : ''}`}</div>
                                        <div className="mpk-flex align-center">
                                            <TextField
                                                id={`qty-${d.id}`}
                                                value={d.qty}
                                                label={t.translate('column.qty')}
                                                type="number"
                                                disabled={!d.checked || tipeOrder === 'POSTPAID'}
                                                onChange={(e) => {
                                                    setProdukList( list => {
                                                        list[i].qty = e.target.value
                                                        return [...list]
                                                    })
                                                }}
                                                style={{width: 120}}
                                            />
                                            <TextField
                                                id={`total-${d.id}`}
                                                value={numeral(Number(d.qty)*d.harga).format('0,0')}
                                                label={t.translate('column.amount')}
                                                className="flex mpk-margin-S margin-left"
                                                disabled={!d.checked}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                },
                {
                    render: () => (
                        <Hint 
                            className="mpk-full full-width mpk-padding-N padding-top"
                            showIcon={false}
                        >
                            <div className="mpk-font size-S mpk-align align-right mpk-full full-width">
                                {t.translate('column.amountTotal')}
                            </div>
                            <div className="mpk-font weight-XB size-XXL mpk-align align-right mpk-full full-width">
                                Rp.{numeral(amountTotal).format('0,00')}
                            </div>
                        </Hint>
                    )
                },
            ]}
            onSubmit={async (___, callback) => {
                try {
                    let postData = {
                        amountTotal,
                        details: produkList.filter(d => (d.checked)).map(d => ({
                            qty: d.qty,
                            produk: d
                        })),
                        tipeOrder
                    }
                    let res = await transactionService.post(postData)
                    window.dispatchEvent(new CustomEvent('payment-widget-message', {detail: {
                        visible: true,
                        order: res.data,
                        tableBaseId: 'mod-transaction-list'
                    }}))
                    callback(false)
                } catch (error) {
                    callback(error, true, false)
                }
            }}
            {...props}
       />
    )
}

export default OrderForm
