import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./Produk.service";
import moment from "moment";
import ProdukDialog from "./Produk.dialog";
import { inject, observer } from "mobx-react";

const _baseId = "produk-table";

const ProdukTable = ({
  className = "",
  showCommandbar = true,
  modalStore,
  history,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={t.translate("modules.produk.title")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="createdDate"
        selectable={false}
        useQueryHistory={false}
        columns={[
          {
            label: "Kode",
            key: "kode",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.kode,
          },
          {
            label: "Nama",
            key: "nama",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (
              <TableWrapper.Link to={`/internal/produk/${item.id}`}>
                {item.nama}
              </TableWrapper.Link>
            ),
          },
          {
            label: "Keterangan",
            key: "keterangan",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.keterangan,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null)
              setShowForm(true)
            }, 
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => history.push(`/internal/produk/${item.id}`),
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              modalStore.showConfirm({
                title: t.translate("sentence.deleteDialogTitle"),
                children: t.translate("sentence.deleteDialogMessege"),
                onSubmit: async (callback) => {
                  await service.delete(item.id);
                  callback(t.translate("sentence.deleteDialogSuccess"));
                  TableWrapper.reload(_baseId);
                },
              });
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <ProdukDialog
        visible={showForm}
        onRequestClose={(data) => {
          TableWrapper.reload(_baseId)
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject("modalStore")(observer(ProdukTable));
