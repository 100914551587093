import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import service from './SatuanProduk.service'
import SatuanService from '../Satuan/Satuan.service'
import t from 'counterpart'
import utils from "../../services/utils";

const defaultData = () => ({});

const SatuanProdukForm = ({
  visible = false,
  onRequestClose = () => {},
  produkId,
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [satuanList, setSatuanList] = useState([]);

  useEffect(() => {
    _getSatuan()
  }, [props.item])

  async function _getSatuan() {
    var res = await SatuanService.get({size: 10000})
    setSatuanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    ready && (
      <DataForm
        baseId="satuan-produk-form"
        title="SATUAN"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={utils.formSerialize(props.item|| defaultData())}
        definitions={[
          {
            inputType: DataForm.inputTypes.SELECT,
            label: 'Satuan',
            key: 'satuan__id',
            options: satuanList,
            validation: 'required'
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Kode",
            key: "kode",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: "Harga",
            key: "harga",
            thousandSeparator: '.',
            decimalSeparator: ',',
            isNumericString: true,
            validation: 'required|numeric',
          },
        ]}
        onBeforeChange={(key, value) => {
          return value;
        }}
        onSubmit={async (data, callback) => {
          var res = {};
          try {
            data = utils.formNormalize(data);
            if (!data.id) {
              res = await service.http.post(`/produk/${produkId}/satuan_produk`, data)
            } else {
              res = await service.http.put(`/produk/${produkId}/satuan_produk`, data);
            }
            callback(t.translate("sentence.dataTelahDiSimpan"), false);
          } catch (e) {
            callback(e, true, false);
          }
        }}
      />
    )
  );
};

export default SatuanProdukForm;
