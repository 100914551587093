import React from 'react'
import { TabsWrapper } from '../../libs/react-mpk/wrapper'
import { inject, observer } from 'mobx-react'
import Transaction from '../Transaction'
import LogActivity from '../LogActivity'
import Profile from '.'

const ProfileDetails = ({
	className = '',
	navigationStore,
	envStore
}) => {
	return (
		<div className="mpk-full full-width full-height mpk-flex direction-column">
			<Profile fit={false} asDetails={true}/>
			<div className="flex mpk-border solid dark border-top">
				<TabsWrapper
					title="Details Sample"
					baseId="mod-details-sample"
					className={className}
					showCommandbar={false}
					tabs={[
						{
							label: 'Transaksi',
							key: 'transaction',
							render: (
								<Transaction
									showCommandbar={false}
									useFilter={true}
								/>
							)
						},
						{
							label: 'Log Aktifitas',
							key: 'log-activity',
							render: (
								<LogActivity
									showCommandbar={false}
									useFilter={true}
								/>
							)
						}
					]}
					onChange={(tab, callback) => {
						navigationStore.redirectTo(`/${envStore.widget.active ? 'widget' : envStore.env.applicationType}/profile-details/${tab.key}`)
						callback()
					}}
				/>
			</div>
		</div>
	)
}

export default inject('navigationStore', 'envStore')(observer(ProfileDetails))
