import React, {useState, useEffect, useRef, useCallback} from 'react'
import { Button, TextIconSpacing, FontIcon, CircularProgress, Checkbox, Radio, Select, FileInput, Table, TableBody, TableRow, TableCell, TableHeader } from 'react-md'
import t from 'counterpart'
import Hint from '../Hint'
import Flex from '../Flex'
import Modal from '../Modal'
import Pagination from '../Pagination'
import CustomInput from '../CustomInput'
import validator from 'validatorjs'
import { number, toast, utils } from '../../services'
import { inputTypes, eventTypes, formatTypes } from '../../config/constant'
import Label from '../Label'
import { clone, cloneDeep } from 'lodash'
import { formDefinition } from '../../class'
import LoadingButton from '../LoadingButton'
import config from './DataForm.config'
import DataFormListTable from './DataFormListTable'
import numeral from 'numeral'
import { inject, observer } from 'mobx-react'

const getEventName = baseId => (`${baseId}-data-form-event`)
const DataForm = ({
  asDialog                  = false,
  useForm                   = true,
  additionalAction          = null,
  baseId                    = 'mpk-data-form-id',
  className                 = '',
  cancelLabel               = t.translate('mpk.column.cancel'),
  defaultData               = {},
  definitions               = [],
  hintMessage               = '',
  hintIconClassName         = 'mdi mdi-information',
  hintMore                  = '',
  hintShowIcon              = true,
  style                     = {},
  onInitData                = null,
  onBeforeChange            = null,
  onChange                  = null,
  onSubmit                  = null,
  submitLabel               = t.translate('mpk.column.submit'),
  submitIconClassName       = 'mdi mdi-check',
  usePadding                = true,
  tableForm                 = false, // Custom Unifikasi
  title                     = null,
  editable                  = true,
  customRules               = null,  // Custom Rules
  watchDefaultData          = true,
  resetDataOnVisible        = true,
  transparent               = false,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [errorValidation, setErrorValidation] = useState(null)
  const [listQuery, setListQuery] = useState({})
  const [listTotal, setListTotal] = useState({})
  const [listData, setListData] = useState({})
  const [showForms, setShowForms] = useState({})
  const [dataVersion, setDataVersion] = useState(0)
  const dataRef = useRef(data)

  const getValidationVal = (__validation, __data=data) => {
    return typeof __validation === 'function' ? __validation(__data) : __validation
  }

  const handleValidate = (sourceData, sourceDef) => {
    setErrorValidation(null)

    return new Promise((resolve, reject) => {
      let rules = {}
      if(sourceDef){
        for(let def2 of sourceDef.definitions){
          if(def2.validation) rules[def2.key] = getValidationVal(def2.validation, sourceData)
        }
      }else{
        if(tableForm){
          var customDefinitions = []
          definitions.header.map((d)=> {
            customDefinitions.push(d)
          })
          definitions.body.map((d)=> {
            d.map((d2)=> {
              customDefinitions.push(d2)
            })
          })
          for(let def of customDefinitions){
            if(def.inputType === inputTypes.LIST){
              for(let idx = 0 ; idx < data[def.key].length ; idx++){
                for(let def2 of def.definitions){
                  if(def2.validation) rules[`${def.key}.${idx}.${def2.key}`] = getValidationVal(def2.validation, data[def.key][idx])//def2.validation
                }
              }
            }else{
              if(def.validation) rules[def.key] = getValidationVal(def.validation)//def.validation
            }
          }
        } else {
          for(let def of definitions){
            if(def.inputType === inputTypes.LIST){
              for(let idx = 0 ; idx < data[def.key].length ; idx++){
                for(let def2 of def.definitions){
                  if(def2.validation) rules[`${def.key}.${idx}.${def2.key}`] = getValidationVal(def2.validation, data[def.key][idx])
                }
              }
            }else{
              if(def.validation) rules[def.key] = getValidationVal(def.validation)
            }
          }
        }

        rules = Object.assign(rules, customRules);
      }
      
      const validation = new validator(sourceData || data, rules)
      if(validation.fails()) {
        let newErrorValidation = {}
        for(let key of Object.keys(rules)){
          let err = validation.errors.first(key)
          if(err) newErrorValidation[key.replace(/\./g, '_')] = err
        }
        
        setErrorValidation(newErrorValidation)
        // toast.warning(t.translate('mpk.sentence.errorValidation'))
        reject(t.translate('mpk.sentence.errorValidation'))
      } else resolve()
    })
  }
  window.handleSubmit = ()=> {
    handleSubmitFromWindow();
  }

  const handleSubmitFromWindow = (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()
    } catch(e){}
    setErrorValidation(null)

    // Custom Unifikasi
    let rules = {}
    if(tableForm){
      var customDefinitions = []
      definitions.header.map((d)=> {
        customDefinitions.push(d)
      })
      definitions.body.map((d)=> {
        d.map((d2)=> {
          customDefinitions.push(d2)
        })
      })
      for(let def of customDefinitions){
        if(def.inputType === inputTypes.LIST){
          for(let idx = 0 ; idx < data[def.key].length ; idx++){
            for(let def2 of def.definitions){
              if(def2.validation) rules[`${def.key}.${idx}.${def2.key}`] = getValidationVal(def2.validation, data[def.key][idx])//def2.validation
            }
          }
        }else{
          if(def.validation) rules[def.key] = getValidationVal(def.validation)//def.validation
        }
      }
    } else {
      for(let def of definitions){
        if(def.inputType === inputTypes.LIST){
          for(let idx = 0 ; idx < data[def.key].length ; idx++){
            for(let def2 of def.definitions){
              if(def2.validation) rules[`${def.key}.${idx}.${def2.key}`] = getValidationVal(def2.validation, data[def.key][idx])
            }
          }
        }else{
          if(def.validation) rules[def.key] = getValidationVal(def.validation)
        }
      }
    }

    rules = Object.assign(rules, customRules);
    try {
      props.removeRules.map((d)=> {
        delete rules[d]
      })
    } catch(e){}

    const validation = new validator(data, rules)
    if(validation.fails()) {
      let newErrorValidation = {}
      for(let key of Object.keys(rules)){
        let err = validation.errors.first(key)
        if(err) newErrorValidation[key.replace(/\./g, '_')] = err
      }
      setErrorValidation(newErrorValidation)
      toast.warning(t.translate('mpk.sentence.errorValidation'))
      window.handleSubmitResult = false
    } else {
      window.handleSubmitResult = true
      if(onSubmit){
        setLoading(true)
        onSubmit(data, (response, asError=true, autoClose=true) => {
          if(response) {
            if(asError) {
              if(typeof response === 'string') toast.error(response)
              else toast.errorRequest(response);
            } else if(response && typeof response === 'string') toast.success(response)
          }
          if(asDialog && autoClose) handleDialogClose()
          setLoading(false)
        })
      }
    }
  }

  const handleSubmit = async (e) => {
    try{
      e.stopPropagation()
      e.preventDefault()
      setErrorValidation(null)
      
      /*
      // Custom Unifikasi
      let rules = {}
      if(tableForm){
        var customDefinitions = []
        definitions.header.map((d)=> {
          customDefinitions.push(d)
        })
        definitions.body.map((d)=> {
          d.map((d2)=> {
            customDefinitions.push(d2)
          })
        })
        for(let def of customDefinitions){
          if(def.inputType === inputTypes.LIST){
            for(let idx = 0 ; idx < data[def.key].length ; idx++){
              for(let def2 of def.definitions){
                if(def2.validation) rules[`${def.key}.${idx}.${def2.key}`] = getValidationVal(def2.validation, data[def.key][idx])//def2.validation
              }
            }
          }else{
            if(def.validation) rules[def.key] = getValidationVal(def.validation)//def.validation
          }
        }
      } else {
        for(let def of definitions){
          if(def.inputType === inputTypes.LIST){
            for(let idx = 0 ; idx < data[def.key].length ; idx++){
              for(let def2 of def.definitions){
                if(def2.validation) rules[`${def.key}.${idx}.${def2.key}`] = getValidationVal(def2.validation, data[def.key][idx])
              }
            }
          }else{
            if(def.validation) rules[def.key] = getValidationVal(def.validation)
          }
        }
      }

      rules = Object.assign(rules, customRules);
     
      const validation = new validator(data, rules)
      if(validation.fails()) {
        let newErrorValidation = {}
        for(let key of Object.keys(rules)){
          let err = validation.errors.first(key)
          if(err) newErrorValidation[key.replace(/\./g, '_')] = err
        }

        setErrorValidation(newErrorValidation)
        console.log(newErrorValidation)
        toast.warning(t.translate('mpk.sentence.errorValidation'))
      } else {
        if(onSubmit){
          setLoading(true)
          onSubmit(data, (response, asError=true, autoClose=true) => {
            if(response) {
              if(asError) {
                if(typeof response === 'string') toast.error(response)
                else toast.errorRequest(response);
              } else if(response && typeof response === 'string') toast.success(response)
            }
            if(asDialog && autoClose) handleDialogClose()
            setLoading(false)
          })
        }
      }
      */
      
      await handleValidate()
      if(onSubmit){
        setLoading(true)
        onSubmit(data, (response, asError=true, autoClose=true) => {
          if(response) {
            if(asError) {
              if(typeof response === 'string') toast.error(response)
              else toast.errorRequest(response);
            } else if(response && typeof response === 'string') toast.success(response)
          }
          if(asDialog && autoClose) handleDialogClose()
          setLoading(false)
        })
      }
    }catch(error){
      console.log(error)
      toast.warning(error)
    }
  }

  const clearFile = (def, parentKey, indexAtParent) => {
    handleChange(def.key, def.multiline ? [] : null, indexAtParent)
  }

  const handleFile = async (def, e, parentKey, indexAtParent) => {
    let obj
    if(def.multiline){
      obj = []
      for(let file of e.target.files){
        obj.push({file})
      }
    } else {
      obj = e.target.files[0]
    }

    handleChange(def.key, obj, parentKey, indexAtParent)
  }

  const handleChange = async (key, value, parentKey, indexAtParent, def={}) => {
    const asText = [inputTypes.INPUT, inputTypes.TEXTAREA].indexOf(def.inputType) >= 0
    let obj = {}
    let tmpData = null
    if(def.formatType === formatTypes.MONEY) value = number.format(value.replace(/\D/g, ''))
    if(onBeforeChange) {
      value = !asText
      ? await onBeforeChange(key, value, parentKey, (changeOther) => {
        tmpData = changeOther
      })
      : onBeforeChange(key, value, parentKey, (changeOther) => {
        tmpData = changeOther
      })
    }

    if(config.onBeforeChange) {
      value = !asText
        ? await config.onBeforeChange(key, value, parentKey)
        : config.onBeforeChange(key, value, parentKey)
    }

    if(parentKey && !isNaN(indexAtParent)){
      let parentData = data[parentKey]
      if(parentData && parentData[indexAtParent]){
        parentData[indexAtParent][key] = value
        if(tmpData) parentData[indexAtParent] = Object.assign(parentData[indexAtParent], tmpData)
        obj[parentKey] = parentData
      }
    } else {
      obj[key] = value
      if(tmpData) obj = Object.assign(obj, tmpData)
    }

    let newObj = Object.assign(data, obj)
    if(onChange) {
      newObj = !asText
        ? await onChange(newObj, key, value, parentKey, indexAtParent)
        : onChange(newObj, key, value, parentKey, indexAtParent)
    }

    setData(d => {
      return {...d, ...newObj}
    })
  }

  const handleRemoveListItem = async (parentKey, indexAtParent, indexAtListData) => {
    let obj = data[parentKey]
    obj.splice(indexAtParent, 1)
    let newObj = cloneDeep(data)
    if(onChange) newObj = await onChange(data, parentKey, obj, parentKey, indexAtParent)
    setData(d => ({...d, ...newObj}))
    // handleChangeListQuery({page: 1, size: 10}, parentKey)
    let objListData = listData[parentKey]
    objListData.splice(indexAtListData, 1)
    setListData(d => ({...d, ...objListData}))

    let { page, size } = listQuery[parentKey]
    if(objListData.length < 1 && page > 1)
    handleChangeListQuery({page: page - 1, size}, parentKey)
  }

  const handleClearList = (parentKey) => {
    setListData(d => {
      d[parentKey] = []
      return d
    })
    
    let obj = {}
    obj[parentKey] = []
    setData(d => ({...d, ...obj}))
    handleChangeListQuery({page: 1, size: 10}, parentKey)
  }

  const handleDialogClose = () => {
    if(props.onRequestClose){
      props.onRequestClose()
    }
    if(resetDataOnVisible) 
      setTimeout(async () =>  setData(onInitData ? await onInitData(cloneDeep(defaultData)) : cloneDeep(defaultData)), 500)
  }

  const initListQuery = () => {
    for(let def of definitions){
      let lq = {}
      if(def.inputType === inputTypes.LIST){
        lq[def.key] = {
          page: 1,
          size: 20,
          key: def.key,
          ...def
        }
      }
      setListQuery(lq)
    }
  }

  const handleChangeListQuery = useCallback(({page, size}, key) => {
    let __update = {}
    __update[key] = {...listQuery[key], page, size, key }
    setListQuery(q => ({...q, ...__update}))
  })

  const handleListData = async (reloadData= true) => {
    let __listData = {}
    try{
      for(let key of Object.keys(listQuery)){
        let {page, size, ...def} = listQuery[key]
        if(def.onFetchData && reloadData){
          let res = await def.onFetchData({page, size})
          __listData[key] = res.data
          setData(d => {
            d[key] = res.data
            return {...d}
          })
          setListTotal( d => {
            d[key] = res.headers['x-pagination-count'] || res.headers['x-total-count']
            return {...d}
          })
        } else {
          let {page, size} = listQuery[key]
          let maxIndex = page*size
          __listData[key] = data[key].length > 0 ? data[key].slice((page-1)*size, data[key].length > maxIndex ? maxIndex : data[key.length]) : []
        }
      }
      setListData(__listData)
    }catch(error){
      toast.errorRequest(error)
    }
  }

  useEffect(() => {
    dataRef.current = data
    // handleListData()
  }, [data])

  useEffect(() => {
    handleListData()
  }, [listQuery])

  useEffect(() => {
    handleListData()
  }, [dataVersion])

  useEffect(async () => {
    const eventName = getEventName(baseId)
    if(!watchDefaultData) setData(onInitData ? await onInitData(cloneDeep(defaultData)) : cloneDeep(defaultData))
    initListQuery()
    window.addEventListener(eventName, (e) => {
      let { eventType, eventData, eventCallback=()=>{}} = e.detail
      switch(eventType){
        case eventTypes.SUBMIT:
          const f = document.getElementById(`${baseId}-form-trigger`)
          if(f) f.click()
          break;
        case eventTypes.RESET:
          setData(cloneDeep(defaultData))
          break;
        case eventTypes.GET:
          eventCallback(cloneDeep(dataRef.current))
          break;
        case eventTypes.UPDATE:
          setDataVersion(d => (d+1))
          setData(cloneDeep(eventData));
          break;
        case eventTypes.RELOAD:
          console.log('RELOAD')
          setDataVersion(d => (d+1))
          break;
        default:
          break;
      }
    }, [])

    return function cleanup(){
      window.removeEventListener(eventName, () => {}, false)
    }
  }, [])

  useEffect(async () => {
    if(watchDefaultData){
      let __defaultData = onInitData ? await onInitData(cloneDeep(defaultData)) : cloneDeep(defaultData)
      setData((oldData) => {
        return __defaultData
      })
    }
  }, [defaultData])

  const render = (def, index, parentKey, indexAtParent=0, elementKey=String(`key-${Math.random()*99999}`)) => {
    const {
      inputType          = null,
      className          = '',
      width              = `100%`,
      style              = {},
      onRelease          = null,
      ...defProps
    } = def

    defProps.label = defProps.label ? `${typeof defProps.label === 'function' ? defProps.label(data) : defProps.label}${defProps.required ? '*' : ''}` : null
    defProps.disabled = typeof defProps.disabled === 'function' ? defProps.disabled(data) : defProps.disabled
    defProps.helpText = typeof defProps.helpText === 'function' ? defProps.helpText(data) : defProps.helpText
    defProps.options = defProps.options 
      ? (typeof defProps.options === 'function' ? defProps.options(data, parentKey, indexAtParent) : defProps.options) 
      : null
    if(onRelease){
      defProps.onBlur = async (e) => {
        let obj = await onRelease(e.target.value, data)
        if(obj) setData(d => ({...d, ...obj}));
      }
    }
    
    const id = `${baseId}-${inputType}-${index}-${parentKey || 'root'}-${indexAtParent}`
    const newClassName = `input mpk-margin-N margin-bottom flex-none ${className}`
    
    let value = def.value
        ? def.value
        : (
          parentKey && typeof data[parentKey][indexAtParent] === 'object'
            ? data[parentKey][indexAtParent][def.key]
            : data[def.key]
        )

    let errorMessage = errorValidation
      ? (parentKey 
        ? errorValidation[`${parentKey}_${indexAtParent}_${def.key}`]
        : errorValidation[def.key]
      ) : null

    errorMessage = errorMessage || (errorValidation ? errorValidation[def.key] : null )

    if(errorMessage) {
      errorMessage = errorMessage.replace(def.key, def.label)
    }

    if(typeof def.show === 'undefined' || (typeof def.show === 'function' ? def.show(data) : def.show)){
      switch(inputType){
        case inputTypes.PRE_DATA:
          return (<pre key={elementKey}>{JSON.stringify(data, null, 2)}</pre>)
        case inputTypes.LIST:
          const {
            key,
            hintMessage       = null,
            hintShowIcon      = true,
            hintIconClassName = null,
            hintMore          = null,
          } = def
          return (
            <div
              className={`mpk-full full-width`}
              // key={elementKey}
            >
              <Label>{def.label}</Label>
              {hintMessage && (
                  <Hint
                    className="mpk-margin-N margin-top margin-bottom"
                    message={hintMessage}
                    showIcon={hintShowIcon}
                    iconClassName={hintIconClassName}
                    more={hintMore}
                />
              )}
              {Array.isArray(data[key]) && listQuery[def.key] && Array.isArray(listData[key]) &&
                <>
                  { def.asTable ? (
                    <div className="mpk-full full-width mpk-scrollable mpk-padding-S padding-bottom">
                      <DataFormListTable 
                        baseId={`table-${def.key}`}
                        data={listData[key]}
                        columns={def.definitions.map((ldef, ldefi) => ({
                          label: ldef.label,
                          render: (item, itemIndex) => (
                            <div 
                              className={ldef.className}
                              style={ldef.style}
                            >
                              {(() => {
                                switch(ldef.inputType){
                                  case inputTypes.INPUT_MASK_NUMBER:
                                    return (ldef.decimalScale && Number(ldef.decimalScale) !== 0) || !ldef.decimalSeparator
                                      ? (item ? item[ldef.key] : '')
                                      : numeral(item ? item[ldef.key] : 0).format('0,00');
                                  case inputTypes.SELECT:
                                    return (
                                      <Select 
                                        id={`${baseId}-${def.key}-${ldef.key}-${itemIndex}`}
                                        value={item ? item[ldef.key] : null} 
                                        options={
                                          Array.isArray(ldef.options) 
                                          ? ldef.options 
                                          : ldef.options(data, def.key, itemIndex)}
                                        readOnly={true}
                                        theme='none'
                                        disableMovementChange={true}
                                      />
                                    );
                                  default:
                                    return item ? item[ldef.key] : null
                                }
                              })()}
                              {/* {ldef.type === inputTypes.INPUT_MASK_NUMBER 
                                ? numeral(item[ldef.key]).format('0,00')
                                : item[ldef.key]
                              } */}
                            </div>
                          )
                        }))}
                        itemActions={[
                          {
                            label: t.translate('mpk.column.edit'),
                            iconClassName: 'mdi mdi-pencil',
                            onClick: (item, i) => {
                              setErrorValidation(null)
                              let __idx = i + ((listQuery[def.key].page-1) * listQuery[def.key].size)
                              setShowForms(d => {
                                d[def.key] = {
                                  visible: true,
                                  item: cloneDeep(item),
                                  i
                                }
                                return {...d}
                              })
                            }
                          },
                          {
                            label: t.translate('mpk.column.delete'),
                            iconClassName: 'mdi mdi-delete',
                            onClick: (item, i) => {
                              props.modalStore.showConfirm({
                                title: t.translate('mpk.sentence.removeTitle'),
                                children: t.translate('mpk.sentence.removeDataConfirmation'),
                                onSubmit: async (callback) => {
                                  try{
                                    if(def.onDelete){
                                      await def.onDelete(item)
                                      if(listQuery[def.key].page === 1 && listTotal[def.key] > 1){
                                        handleListData()
                                      }
                                    } 
                                    let __idx = i + ((listQuery[def.key].page-1) * listQuery[def.key].size)
                                    handleRemoveListItem(def.key, __idx, i)
                                    callback()
                                  }catch(error){
                                    callback()
                                    toast.errorRequest(error)
                                  }
                                }
                              })
                            }
                          }
                        ]}
                        {...def}
                      />
                      <Modal.Submit
                        title={def.label}
                        baseId={`table-form-${def.key}`}
                        visible={showForms[def.key] ? showForms[def.key].visible : false }
                        onRequestClose={() => {
                          let {i, item } = showForms[def.key]
                          setData(d => {
                            if(item) d[def.key][i] = item;
                            else d[def.key].splice(i, 1)
                            return {...d}
                          })
                          setShowForms(d => {
                            d[def.key] = {
                              visible: false,
                              item: null,
                              i: null
                            }
                            return {...d}
                          })
                          if(!def.onFetchData) 
                            setDataVersion(d => (d+1))
                        }}
                        onSubmit={async() => {
                          try {
                            let i = showForms[def.key].i
                            await handleValidate(data[def.key][i], def)
                            if(def.onSubmit){
                              let newItem = await def.onSubmit(data[def.key][i])
                              setListTotal(d => {
                                d[def.key] = Number(d[def.key]) + 1
                                return {...d}
                              })
                              if(newItem){
                                setData(d => {
                                  d[def.key][i] = {...d[def.key][i], ...newItem}
                                  return d
                                })
                              }
                            }
                            setShowForms(d => {
                              d[def.key] = {
                                visible: false,
                                item: null,
                                i: null
                              }
                              return {...d}
                            })
                          } catch (error) {
                            console.log(error)
                            if(def.onSubmit) toast.errorRequest(error)
                            else toast.warning(error)
                          }

                        }}
                        style={{maxWidth: 800}}
                      >
                        <div className="mpk-flex wrap mpk-full full-width">
                          {showForms[def.key] && showForms[def.key].visible && def.definitions.map((ldef, ldefi) => {
                            let i = showForms[def.key].i
                            let __idx = i + ((listQuery[def.key].page-1) * listQuery[def.key].size)
                            return render(ldef, index, def.key, i, `${def.key}-${__idx}-${ldefi}`)
                          })}
                        </div>
                      </Modal.Submit>
                    </div>
                  ) : (
                    listData[key].map((d, i) => {
                      let __idx = i + ((listQuery[def.key].page-1) * listQuery[def.key].size)
                      return d && (
                        <div
                          key={`${baseId}-${def.key}-${i}`}
                          className="mpk-paper mpk-border thin solid dark border-all mpk-padding-N padding-all mpk-margin-N margin-bottom"
                        >
                          <div className="mpk-min-width-S mpk-padding-N  padding-right padding-bottom mpk-font weight-XB size-B mpk-margin-N margin-bottom">
                            {i+1}
                          </div>
                          <Flex
                            className="mpk-full full-width"
                            direction={Flex.properties.direction.ROW}
                            wrap
                          >
                            {def.definitions.map((ldef, ldefi) => (
                              render(ldef, index, def.key, i, `${def.key}-${__idx}-${ldefi}`)
                            ))}
                          </Flex>
                          { editable && (
                            <Button
                              theme="warning"
                              onClick={() => handleRemoveListItem(def.key, __idx, i)}
                              disabled={loading}
                            >
                              <TextIconSpacing
                                icon={<FontIcon iconClassName="mdi mdi-delete"/>}
                              >
                                {t.translate('mpk.column.delete')}
                              </TextIconSpacing>
                            </Button>
                          )}
                        </div>
                      )
                    })
                  )}
                  <div
                    className="mpk-margin-N margin-bottom"
                  >
                    <Pagination 
                      page={listQuery[def.key].page} 
                      size={listQuery[def.key].size}
                      totalData={def.onFetchData ? listTotal[def.key] : data[key].length}
                      justify={Pagination.justifies.START}
                      onChange={(ev) => handleChangeListQuery(ev, def.key)}
                    />
                  </div>
                </> 
              }
              { editable && (
                <Flex
                  align={Flex.properties.align.CENTER}
                  justify={def.onFetchData ? Flex.properties.justify.BETWEEN : Flex.properties.justify.START}
                  className="mpk-full full-width"
                >
                  <Flex 
                    align={Flex.properties.align.CENTER}
                  >
                    {def.additionalAction}
                    <Button
                      themeType="outline"
                      className="mpk-margin-S margin-right"
                      disabled={loading}
                      onClick={() => {
                        if(!Array.isArray(data[key])) data[key] = []
                          data[key].push(def.defaultData ?  cloneDeep(def.defaultData) : {})
                          handleChange(key, data[key])
                          if(!def.onFetchData) handleListData()
                          if(def.asTable){
                            setShowForms(d => {
                              d[def.key] = {
                                visible: true,
                                item: null,
                                i: data[key].length - 1
                              }
                              return {...d}
                            })
                          }
                        }
                      }
                    >
                      <TextIconSpacing
                        icon={<FontIcon iconClassName="mdi mdi-plus"/>}
                      >
                        {t.translate('mpk.column.add')}
                      </TextIconSpacing>
                    </Button>
                    {def.onFetchData && (
                      <Button
                        themeType="outline"
                        className="mpk-margin-S margin-right"
                        disabled={loading}
                        onClick={() => {
                          handleChangeListQuery({page: 1, size: 20}, def.key)
                        }}
                      >
                        <TextIconSpacing
                          icon={<FontIcon iconClassName="mdi mdi-reload"/>}
                        >
                          {t.translate('mpk.column.reload')}
                        </TextIconSpacing>
                      </Button>
                    )}
                  </Flex>
                  <Flex
                    align={Flex.properties.align.CENTER}
                  >
                    <Button
                      theme="warning"
                      themeType="outline"
                      disabled={loading}
                      onClick={async () => {
                        props.modalStore.showConfirm({
                          title: t.translate('mpk.sentence.removeTitle'),
                          children: t.translate(def.asTable ? 'mpk.sentence.removeAllTableDataConfirmation' : 'mpk.sentence.removeAllDataConfirmation'),
                          onSubmit: async (callback) => {
                            try{
                              if(def.onReset) await def.onReset()
                              handleClearList(def.key)
                              callback()
                            }catch(error){
                              callback()
                              toast.errorRequest(error)
                            }
                          }
                        })
                      }}
                    >
                      <TextIconSpacing
                        icon={<FontIcon iconClassName="mdi mdi-alert"/>}
                      >
                        {t.translate('mpk.column.clear')}
                      </TextIconSpacing>
                    </Button>
                  </Flex>
                </Flex>
              )}
            </div>
          )
        case inputTypes.FILE_INPUT:
          return (
            <>
              <div 
                className={`mpk-flex justify-center mpk-full full-width ${newClassName}`}
                key={elementKey}
              >
                <FileInput
                  id={id}
                  className={`flex-none mpk-margin-S margin-right`}
                  value={value}
                  onChange={e => handleFile(def, e, parentKey, indexAtParent)}
                  disabled={loading}
                  theme="primary"
                  themeType={def.multiline
                    ? Array.isArray(value) && value.length > 0 ? 'contained' : 'outline'
                    : [null, undefined, ''].indexOf(value) >= 0 ? 'outline' : 'contained'
                  }
                  buttonType="text"
                  readOnly={!editable}
                  {...defProps}
                  required={false}
                  icon={<FontIcon iconClassName="mdi mdi-file"/>}
                >
                  {defProps.label}
                </FileInput>
                <CustomInput
                  id={`${id}-file-info`}
                  className="flex"
                  theme="outline"
                  value={def.multiline
                    ? Array.isArray(value) ? value.map(d => (d.file.name)).toString() : ''
                    : value ? value.name : ''
                  }
                  required={defProps.required}
                  helpText={defProps.helpText}
                  placeholder={defProps.placeholder}
                />
                <Button
                  themeType="flat"
                  className="mpk-margin-N margin-left"
                  onClick={() => clearFile(def, parentKey, indexAtParent)}
                >
                  <FontIcon iconClassName="mdi mdi-delete"/>
                </Button>
              </div>
              {errorMessage &&
                <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ width: '100%', color: '#f1420c', fontSize: 12, padding: '8px 0', marginBottom: 12 }}>
                  <FontIcon
                    iconClassName="mdi mdi-alert"
                    style={{fontSize:15, color: '#f1420c' }}
                    className="mpk-margin-S margin-right"
                  />
                  {errorMessage}
                </div>
              }
            </>
          )
        case inputTypes.RADIO:
          var defaultValue = "";
          if(def.defaultValue) defaultValue = def.defaultValue
          if(data && data[defProps.key]) defaultValue = (value + "") == data[defProps.key]
          return (
            <Radio
              id={id}
              className={newClassName}
              key={elementKey}
              value={def.value}
              name={def.name}
              checked={defaultValue}
              onChange={e => handleChange(def.key, e.target.value, parentKey, indexAtParent)}
              disabled={loading}
              readOnly={!editable}
              {...defProps}
              style={def.style}
            />
          )
        case inputTypes.CHECKBOX:
          if(!def.width) def.width = '100%'
          var inputForm = [
            <div 
              style={{
                width: def.width,
                ...def.style
              }}
              key={elementKey}
            >
              <Checkbox
                id={id}
                className={`mpk-full full-width ${newClassName}`}
                checked={value}
                defaultChecked={defProps.defaultValue}
                onChange={(e, checked) => handleChange(def.key, e.target.checked, parentKey, indexAtParent)}
                disabled={loading}
                readOnly={!editable}
                {...defProps}
                style={def.style}
              />
              {errorMessage &&
                <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ width: '100%', color: '#f1420c', fontSize: 12, padding: '8px 0', marginBottom: 12 }}>
                  <FontIcon
                    iconClassName="mdi mdi-alert"
                    style={{fontSize:15, color: '#f1420c' }}
                    className="mpk-margin-S margin-right"
                  />
                  {errorMessage}
                </div>
              }
            </div>
          ]
          return (
            <>
              {tableForm && 
                <TableCell colSpan={def.colSpan}>
                  {inputForm}
                </TableCell>
              }
              {!tableForm && 
                  <>{inputForm}</>
              }
            </>
          )
        case inputTypes.MULTI_CHECKBOX:
          const isChecked = (d) => {
            let v = typeof d === 'object' ? d.value : d
            return value && value.indexOf(v) >= 0 ? true : false
          }
          return def.options && (
            <div 
              className="mpk-flex direction-column mpk-full full-width"
              key={elementKey}
            >
              {def.label && (
                <Label
                  className={`mpk-padding-N padding-top mpk-margin-XS margin-top ${newClassName}`}
                >
                  {def.label}
                </Label>
              )}
              {def.options.map((d, i) => (
                <Checkbox
                  id={`${id}-${def.key}-${i}`}
                  key={`${id}-${def.key}-${i}`}
                  className={`mpk-full full-width ${newClassName}`}
                  checked={isChecked(d)}
                  onChange={(e, checked) => {
                    if(value){
                      let itemValue = typeof d === 'object' ? d.value : d
                      if(e.target.checked) value.push(itemValue);
                      else value.splice(value.indexOf(itemValue), 1);
                      handleChange(def.key, value, parentKey, indexAtParent)}
                    }
                  }
                  disabled={loading}
                  readOnly={!editable}
                  {...defProps}
                  label={typeof d === 'object' ? d.label : d }
                  style={def.style}
                />
              ))}
            </div>
          )
        case inputTypes.SELECT:
          var inputForm = [
            <div
              className={`mpk-full full-width ${newClassName}`}
              key={elementKey}
              style={{...style, ...{width}}}
            >
              <Select
                id={id}
                // className={`mpk-full full-width ${newClassName}`}
                className={`mpk-full full-width`}
                value={value}
                onChange={e => handleChange(def.key, e, parentKey, indexAtParent)}
                disabled={loading || !editable}
                {...defProps}
              />
              {errorMessage &&
                <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ color: '#f1420c', fontSize: 12 }}>
                  <FontIcon
                    iconClassName="mdi mdi-alert"
                    style={{fontSize:12}}
                    className="mpk-margin-S margin-right"
                  />
                  {errorMessage}
                </div>
              }
            </div>
          ]
          return (
            <>
              {tableForm && 
                <TableCell colSpan={def.colSpan}>
                  {inputForm}
                </TableCell>
              }
              {!tableForm && 
                  <>{inputForm}</>
              }
            </>
          )
        
        case inputTypes.REACT_SELECT:
        case inputTypes.AUTOCOMPLETE:
        case inputTypes.DATE:
        case inputTypes.DATETIME:
        case inputTypes.INPUT: // Custom Unifikasi
        case inputTypes.INPUT_MASK:
        case inputTypes.INPUT_MASK_NUMBER:
        case inputTypes.TEXTAREA:
        case inputTypes.DATEPICKER:
        return (
          <>
            {tableForm &&
              <TableCell 
                colSpan={def.colSpan}
                key={elementKey}
              >
                <CustomInput
                  style={{ marginTop: '16px' }}
                  id={id}
                  inputType={inputType}
                  className={`mpk-margin-M margin-bottom ${newClassName}`}
                  value={value}
                  onChange={e => {
                    if(inputType === inputTypes.REACT_SELECT){
                      var reactSelectValue = e['value']
                      if(def.isMulti){
                        reactSelectValue = e
                      }
                      handleChange(def.key, reactSelectValue, parentKey, indexAtParent, def)
                    } else {
                      handleChange(def.key, e.target.value, parentKey, indexAtParent, def)
                    }
                  }}
                  errorMessage={errorMessage}
                  type={inputType === inputTypes.INPUT
                    ? 'text'
                    : inputType === inputTypes.DATE || inputType === inputTypes.DATETIME
                      ? 'date'
                      : null
                  }
                  containerStyle={{
                    ...{width},
                    ...style
                  }}
                  disabled={loading}
                  readOnly={!editable}
                  {...defProps}
                />
              </TableCell>
            }
            {!tableForm &&
                <CustomInput
                  id={id}
                  inputType={inputType}
                  className={`mpk-margin-M margin-bottom ${newClassName}`}
                  key={elementKey}
                  value={value}
                  onChange={e => {
                    if(!def.submitOnBlur){
                      if(inputType === inputTypes.REACT_SELECT){
                        var reactSelectValue = e['value']
                        if(def.isMulti){
                          reactSelectValue = e
                        }
                        handleChange(def.key, reactSelectValue, parentKey, indexAtParent, def)
                      } else {
                        handleChange(def.key, e.target.value, parentKey, indexAtParent, def)
                      }
                    }
                  }}
                  onBlur={ def.submitOnBlur ? (e) => {
                    if(inputType === inputTypes.REACT_SELECT){
                      var reactSelectValue = e['value']
                      if(def.isMulti){
                        reactSelectValue = e
                      }
                      handleChange(def.key, reactSelectValue, parentKey, indexAtParent, def)
                    } else {
                      handleChange(def.key, numeral(e.target.value).value(), parentKey, indexAtParent, def)
                    }
                  } : null}
                  onAutoComplete={ e => {
                    let val = def.onAutoComplete ? def.onAutoComplete(e) : e.value
                    handleChange(def.key, val, parentKey, indexAtParent, def)}
                  }
                  errorMessage={errorMessage}
                  type={inputType === inputTypes.INPUT
                    ? 'text'
                    : inputType === inputTypes.DATE || inputType === inputTypes.DATETIME
                      ? 'date'
                      : null
                  }
                  containerStyle={{
                    ...{width},
                    ...style
                  }}
                  disabled={loading}
                  readOnly={!editable}
                  {...defProps}
                />
            }
          </>
          )
        case inputTypes.DIVIDER:
          return (
            <Label
              className={`mpk-padding-N padding-bottom mpk-margin-XS ${newClassName}`}
              key={elementKey}
              {...defProps}
            >{def.label}</Label>
          )
        default:
          return typeof def.render === 'function' ? def.render(data) : def.render
      }
    } else return null;
  }

  const formContent = (
    <div className={`mpk-full full-width ${onSubmit ? 'mpk-margin-N margin-bottom' : ''}`}>
      {hintMessage && (
        <Hint
          message={hintMessage}
          showIcon={hintShowIcon}
          iconClassName={hintIconClassName}
          more={hintMore}
          className="mpk-margin-M margin-bottom"
        />
      )}
      <Flex
        className="mpk-full full-width"
        direction={Flex.properties.direction.ROW}
        wrap
      >
        {tableForm &&
          <Table fullWidth={true}>
            <TableHeader>
              <TableRow>
                {definitions.header.map((def, i) => (
                  render(def, i)
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {definitions.body.map((b, i)=> {
                return (
                  <TableRow key={`${b.key}-${i}`}>
                    {b.map((def, i) => (
                      render(def, i)
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        }
        {!tableForm &&
          <>
            {definitions.map((def, i) => (
              render(def, i)
            ))}
          </>
        }
      </Flex>
    </div>
  )

  const form = (
    <form
      id={baseId}
      className={`mpk-form-wrapper ${transparent ? '' : 'mpk-paper'} ${asDialog ? '' : 'mpk-padding-N padding-all'} ${className}`}
      onSubmit={handleSubmit}
      style={{...{
        width: '100%',
        maxWidth: 800,
        margin: '0 auto',
      }, ...style}}
      {...props}
    >
      {title && (<div className="mpk-font size-XXL weight-B mpk-margin-M margin-bottom">{title}</div>)}
      {formContent}
      {asDialog ? (
        <button style={{display: 'none'}} type="submit" id={`${baseId}-form-trigger`}/>
      ) : (
        <Flex align={Flex.properties.align.CENTER}>
          {additionalAction && (
            Array.isArray(additionalAction) && additionalAction.length > 0 ? (
              <div className="mpk-margin-S margin-right mpk-flex align-center">
                {additionalAction.filter(item => (
                  typeof item.show === 'undefined' 
                    ? true
                    : typeof item.show === 'function' ? item.show(data) : item.show
                )).map(item => (
                  item.render(data)
                ))}
              </div>
            ) : (additionalAction)
          )}

          { editable && (
            <Button
              id={`${baseId}-form-trigger`}
              type="submit"
              themeType="contained"
              theme={loading ? "disabled" : "primary"}
              disabled={loading}
              style={{
                position: 'sticky',
                left: 16
              }}
            >
              <TextIconSpacing
                icon={loading ? (
                  <CircularProgress id={`${baseId}-submit-progress`} centered={false}/>
                ) : <FontIcon iconClassName={submitIconClassName}/>}
              >
                {submitLabel}
              </TextIconSpacing>
            </Button>
          )}
        </Flex>
      )}
    </form>
  )

  const child = onSubmit && useForm ? form : (
    <div
      className={`mpk-form-wrapper ${transparent ? '' : 'mpk-paper'} ${asDialog ? '' : 'mpk-padding-N padding-all'} ${className}`}
      onSubmit={handleSubmit}
      style={{...{
        width: '100%',
        maxWidth: 800,
        margin: '0 auto',
      }, ...style}}
      {...props}
    >
      {title && (<div className="mpk-font size-XXL weight-B mpk-margin-M margin-bottom">{title}</div>)}
      {formContent}
      {additionalAction && (
        Array.isArray(additionalAction) ? (
          <div className="mpk-margin-S margin-right mpk-flex align-center flex">
            {additionalAction.filter(item => (
              typeof item.show === 'undefined' 
                ? true
                : typeof item.show === 'function' ? item.show(data) : item.show
            )).map(item => (
              item.render(data)
            ))}
          </div>
        ) : (additionalAction)
      )}
      {!useForm && (
        <Button
          id={`${baseId}-form-trigger`}
          onClick={handleSubmit}
          themeType="contained"
          theme={loading ? "disabled" : "primary"}
          disabled={loading}
          style={{
            position: 'sticky',
            left: 16
          }}
        >
          <TextIconSpacing
            icon={loading ? (
              <CircularProgress id={`${baseId}-submit-progress`} centered={false}/>
            ) : <FontIcon iconClassName={submitIconClassName}/>}
          >
            {submitLabel}
          </TextIconSpacing>
        </Button>
      )}
    </div>
  )

  // useEffect(() => {
  //   setData(d => ({...d, ...defaultData}))
  // }, [defaultData])

  return asDialog ? (
    <Modal.Submit
      loading={loading}
      onSubmit={editable ? cb => {
        const f = document.getElementById(`${baseId}-form-trigger`)
        if(f) f.click()
      } : null}
      submitLabel={submitLabel}
      submitIconClassName={submitIconClassName}
      showSubmit={onSubmit && editable}
      cancelLabel={cancelLabel}
      {...props}
      onRequestClose={handleDialogClose}
      asForm={false}
    >
      {form}
    </Modal.Submit>
  ) : child
}

DataForm.inputTypes = inputTypes
DataForm.formatTypes = formatTypes
DataForm.definition = formDefinition
DataForm.LoadingButton = LoadingButton
DataForm.submit = (baseId) => {
  const eventName = getEventName(baseId)
  window.dispatchEvent(new CustomEvent(eventName, {detail:{eventType: eventTypes.SUBMIT}}))
}
DataForm.reset = (baseId) => {
  const eventName = getEventName(baseId)
  window.dispatchEvent(new CustomEvent(eventName, {detail:{eventType: eventTypes.RESET}}))
}
DataForm.get = (baseId, callback=()=>{}) => {
  const eventName = getEventName(baseId)
  window.dispatchEvent(new CustomEvent(eventName, {detail:{
    eventType: eventTypes.GET, 
    eventCallback: data => {callback(data)}
  }}))
}
DataForm.update = (baseId, newData) => {
  const eventName = getEventName(baseId)
  window.dispatchEvent(new CustomEvent(eventName, {detail:{
    eventType: eventTypes.UPDATE,
    eventData: newData
  }}))
}
DataForm.reload = (baseId) => {
  const eventName = getEventName(baseId)
  window.dispatchEvent(new CustomEvent(eventName, {detail:{
    eventType: eventTypes.RELOAD
  }}))
}

export default inject('modalStore')(observer(DataForm))
