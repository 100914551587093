import React, {useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './Aplikasi.service'
import moment from 'moment'
import AplikasiForm from './Aplikasi.form'
import { inject, observer } from 'mobx-react'

const _baseId = 'aplikasi-table'

const AplikasiTable = ({
  className       = '',
  showCommandbar  = true,
  modalStore
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  
  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={t.translate('modules.aplikasi.title')}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="createdDate"
        selectable={false}
        useQueryHistory={false}
        columns={[
          {
            label: 'Nama',
            key: 'nama',
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.nama)
          },
          {
            label: 'Client ID',
            key: 'clientId',
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.clientId)
          },
          {
            label: 'Client Secret',
            key: 'clientSecret',
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.clientSecret)
          },

        ]}
        actions={[
          new TableWrapper.action(
            'Add', 
            'mdi mdi-plus', 
            () => {
              setSelectedItem(null)
              setShowForm(true)
            }, 
            true
          )
        ]}
        itemActions={[
          new TableWrapper.action(
            'Edit', 
            'mdi mdi-pencil', 
            (item) => {
              setSelectedItem(item)
              setShowForm(true)
            }, 
            true
          ),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {
            modalStore.showConfirm({
              title       : t.translate('sentence.deleteDialogTitle'),
              children    : t.translate('sentence.deleteDialogMessege'),
              onSubmit    : async (callback) => {
                await service.delete(item.id)
                callback(t.translate('sentence.deleteDialogSuccess'))
                TableWrapper.reload(_baseId)
              }
            })
          }, true)
        ]}
        onFetchData={ async query => {
          return service.get(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <AplikasiForm
        visible={showForm}
        onRequestClose={(data) => {
          TableWrapper.reload(_baseId)
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      />
    </>
  )
}

export default inject('modalStore')(observer(AplikasiTable))
