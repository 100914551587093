export default {
  formNormalize: (data) => {
    Object.keys(data).forEach((d) => {
      if (d.search("__") != -1) {
        var e = d.split("__");
        if (!data[e[0]]) data[e[0]] = {};
        data[e[0]][e[1]] = data[d];
      }
    });

    return data;
  },
  formSerialize: (data) => {
    Object.keys(data).forEach((d) => {
      // number to string
      if (typeof data[d] == "number") {
        data[d] = data[d].toString();
      }

      if (data[d] && typeof data[d] == "object" && data[d].length) {
        data[d].forEach((dataChild) => {
          Object.keys(dataChild).forEach((e) => {
            if (typeof dataChild[e] == "number") {
              dataChild[e] = dataChild[e].toString();
            }
          });
        });
      }

      //objek to __ {parent: {id: 'asd'}} => {parent: {id: 'asd'}, parent__id: 'asd'}
      if (data[d] && typeof data[d] == "object" && !data[d].length) {
        Object.keys(data[d]).forEach((e) => {
          data[d + "__" + e] = data[d][e];
        });
      }
    });

    return data;
  },
}
