import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./Rekonsiliasi.service";
import moment from "moment";
import RekonsiliasiForm from "./Rekonsiliasi.dialog";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
const _baseId = "rekonsiliasi-table";

const RekonsiliasiTable = ({
  className = "",
  showCommandbar = true,
  modalStore,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={t.translate("modules.rekonsiliasi.title")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="createdDate"
        selectable={false}
        useQueryHistory={false}
        columns={[
          {
            label: "Status",
            key: "status",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (
              <TableWrapper.Link to={`/internal/rekonsiliasi/${item.id}`}>
                {item.status}
              </TableWrapper.Link>
            ),
          },
          {
            label: "Dari Tanggal",
            key: "dariTanggal",
            sortable: true,
            type: TableWrapper.dataTypes.DATE,
            render: (item) => item.dariTanggal,
          },
          {
            label: "Ke Tanggal",
            key: "keTanggal",
            sortable: true,
            type: TableWrapper.dataTypes.DATE,
            render: (item) => item.keTanggal,
          },
          {
            label: "Pesan",
            key: "pesan",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.pesan,
          },
          {
            label: "Keterangan",
            key: "keterangan",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.keterangan,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Download Template",
            "mdi mdi-file-import",
            async () => {
              service.http.download(`${service.path}-download-template-import`);
              setSelectedItem(null);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              modalStore.showConfirm({
                title: t.translate("sentence.deleteDialogTitle"),
                children: t.translate("sentence.deleteDialogMessege"),
                onSubmit: async (callback) => {
                  await service.delete(item.id);
                  callback(t.translate("sentence.deleteDialogSuccess"));
                  TableWrapper.reload(_baseId);
                },
              });
            },
            true
          ),
          new TableWrapper.action(
            "Approve",
            "mdi mdi-check",
            (item) => {
              if (item.status === "REKON FINISH") {
                iziToast.warning({
                  message: "Data rekon sudah di approve/selesai.",
                });
              } else {
                modalStore.showConfirm({
                  title: "Approve Rekonsiliasi",
                  children:
                    "Apakah anda yakin akan meng approve rekonsiliasi ini?",
                  onSubmit: async (callback) => {
                    await service.http.get(
                      `${service.path}/${item.id}/approve`
                    );
                    callback("Rekonsiliasi telah di approve");
                    TableWrapper.reload(_baseId);
                  },
                });
              }
            },
            (item) => item.status != "REKON FINISH"
          ),
        ]}
        onFetchData={async (query) => {
          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <RekonsiliasiForm
        visible={showForm}
        onRequestClose={(data) => {
          TableWrapper.reload(_baseId);
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject("modalStore")(observer(RekonsiliasiTable));
