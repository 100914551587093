import { crud } from '../../libs/react-mpk/services';
import http from "../../libs/react-mpk/services/http.service";

let service = new crud('/produk');

service.http = http;
service.put = (data) => {
  let config = service.clearParams();
  return http.request({
    method: http.methods.PUT,
    url: `${service.path}`,
    data,
    options: service.options,
    config,
  });
};

service.getOne = (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${service.path}/${id}`,
    options: service.options,
    config: service.config,
  });
};

service.getImg = async (url, data, config={}, options={}) => {
  config = Object.assign({
    method: 'get',
    responseType: 'arraybuffer',
  }, config)

  options = Object.assign({
    fileType: null, 
    fileName: null
  }, options)
  return new Promise(async (resolve, reject) => {
    try{
      let res = await ( config.method === 'post' ? http.post(url, data, config) : http.get(url, {}, config));
      let type = res.headers['content-type'];
      
      var img=Buffer.from(res.data, 'binary').toString('base64')
      resolve(img);
    }catch(error){
      reject(error);
    }
  })
}

service.getByClientId = (query) => {
  return http.get(`/produk/client_id`, query)
}

export default service;