import React, {useState, useEffect, useCallback, useRef} from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { sso } from '../libs/react-mpk/hocs';
import { toast, http } from '../libs/react-mpk/services';
import Profile, { ProfileDetails } from '../modules/Profile'
import PaymentWidget from '../modules/PaymentWidget';
import qs from 'query-string'
import { Hint, LoaderInfo } from '../libs/react-mpk/components';

const actions = {
    CLOSE_WINDOW:{
      resolver:'close-window-qm'
    },
    READY:{
      initial: 'preparing-qm',
      resolver:'on-ready-qm'
    },
    OPEN_DETAILS: {
        initial: 'open-details',
        changeDefaultProduct: 'change-default-product'
    },
    AUTH:{
        requestAccessToken: 'request-access-token',
        updateAccessToken: 'update-access-token'
    },
    ACTION:{
        reload: 'reload-qm'
    }
}

const Widget = ({ history, ...props }) => {
    const [ready, setReady] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const setupListener = () => {
        let widget = window
        widget.addEventListener('beforeunload', e => {
            post(actions.CLOSE_WINDOW.resolver, null);
        })
        widget.addEventListener('custom-message', handleMessage, false)
    }

    const handleMessage = event => {
        let { eventName, data } = event.detail
        switch(eventName){
            case actions.READY.initial:
                console.log(`[WIDGET] ${data.id} IS READY`)
                post(actions.READY.resolver)
                break;
            case actions.OPEN_DETAILS.changeDefaultProduct:
                window.dispatchEvent(new CustomEvent(eventName))
                break;
            default:
                return null
        }
    }

    const post = (eventName, data) => {
        let widget = window
        let { parentOrigin, id, inline } = props.envStore.widget
        widget[inline ? 'parent' : 'opener'].postMessage({
            eventName, 
            data, 
            id
        }, parentOrigin)
    }

    const checkUrl = () => {
        return new Promise((resolve, reject) => {
            let parsedUrl = qs.parseUrl(window.location.href)
            
            let { clientId, application } = parsedUrl.query
            if(!clientId && !application){
                reject(new Error('Require either client id or application name'))
            }if(application){
                let __applicationClientId = props.envStore.env.defaultApplicationClientId[application]
                if(__applicationClientId){
                    http.setClientId(__applicationClientId)
                    resolve()
                } else {
                    reject(new Error(`Application ${application} is not found`))
                }
            }else {
                resolve()
            }
        })
    }

    useEffect(async () => {
        try {
            let { requestPeriod } = props.envStore.widget
            // await checkUrl()
            props.envStore.setWidget({
                actions,
                post
            })
            setupListener()
            if(requestPeriod){
                setInterval(() => {
                    post(actions.AUTH.requestAccessToken)
                }, requestPeriod*1000)
            } 
            setReady(true)
        } catch (error) {
            post('error', error.message)
            setErrorMessage(error.message)
        }
    }, [])

    return ready ? (
        <>
            <Router history={history}>
                <Switch>
                    <Route
                        path='/widget/profile'
                        render={props => (
                            <Profile
                                {...props} 
                            />
                        )}
                    />
                    <Route
                        path='/widget/profile-details/:key'
                        render={props => (
                            <ProfileDetails
                                {...props} 
                            />
                        )}
                    />
                </Switch>
            </Router>
            <PaymentWidget/>
        </>
    ) : ( errorMessage 
        ? <Hint noBorder={true}>{errorMessage}</Hint>
        : <LoaderInfo/>
    )
}

export default sso({
    basePath: '/widget'
})(Widget);