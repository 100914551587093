import React, { useState, useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { Modal, LoaderInfo } from '../../libs/react-mpk/components'
import './PaymentWidget.scss'
import { TableWrapper } from '../../libs/react-mpk/wrapper'

const PaymentWidget = ({
    ...props
}) => {
    const [isLoading, setIsLoading ] = useState(true)
    const [ visible, setVisible ] = useState(false)
    const [ tableBaseId, setTableBaseId] = useState(null)
    let tableBaseIdRef = useRef()

    const handleCallback = (e) => {
        console.log(e)
    }

    const handleClose = (e) => {
        console.log(e)
        if(tableBaseIdRef.current) TableWrapper.reload(tableBaseIdRef.current) 
        setVisible(false)
    }

    const initialize = (order) => {
        let { widgetInterface } = props.envStore.env
        let accessToken = props.authStore.getAccessToken()
        let accessKey = props.authStore.getAccessKey()
        let { user } = props.authStore
        let { amountTotal, orderNo } = order
        setIsLoading(false)
        setTimeout(() => {
            // console.log('accessKey', accessKey)
            window.pajakkuPay.other(
                user.id,
                user.username,
                orderNo,
                [{
                    amount: amountTotal,
                    billingCode: orderNo
                }],
                accessKey || accessToken,
                widgetInterface.payment.clientId,
                handleCallback,
                handleClose,
                accessKey ? true : false,
                'payment-widget-container'
            )
        })
    }

    useEffect(() => {
        window.addEventListener('payment-widget-message', e => {
            let { order={}, tableBaseId } = e.detail;
            if(e.detail.visible){
                setVisible(e.detail.visible)
                setTimeout(() => initialize(order))
            }
            if(tableBaseId) tableBaseIdRef.current = tableBaseId
        })

        return () => {
            window.removeEventListener('payment-widget-message', () => {}, false)
        }
    }, [])

    return(
        <Modal
            baseId="mod-payment-widget"
            className="mod-payment-widget"
            visible={visible}
            onRequestClose={() => setVisible(false)}
            style={{
                padding: 0
            }}
        >
            { isLoading ? <LoaderInfo/> : (
                <div id="payment-widget-container"/>
            )}
        </Modal>
    )
}

export default inject('envStore', 'authStore', 'temporaryStore')(observer(PaymentWidget))