import React, { useEffect, useState } from 'react'
import { toast, http } from '../../libs/react-mpk/services'
import { LoaderInfo } from '../../libs/react-mpk/components'
import { FontIcon, TooltipHoverModeConfig, Tooltipped } from 'react-md'
import { inject, observer } from 'mobx-react'
import produkService from '../Produk/Produk.service'
import t from 'counterpart'
import './Quota.scss'

const Quota = ({
    produk,
    onClick,
    onGetBalance    = () => {},
    ...props
}) => {
    const [quotaData, setQuotaData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [logo, setLogo] = useState(null)
    const accessToken = props.authStore.getAccessToken()

    const handleFetchQuota = async (isInit=false) => {
        try {
            let res = await http.get(`/quota/${produk.kode}/balance`)
            onGetBalance(res.data)
            setQuotaData(res.data)
            if(isInit && produk.logo){
                let resLogo = await new Promise(async (resolve) => {
                    try{
                        let _resLogo = await produkService.getImg(`/produk/${produk.logo}/logo`)
                        resolve(_resLogo)
                    }catch(error){
                        resolve(null)
                    }
                })
                if(resLogo) setLogo(`data:;base64,${resLogo}`)
            } else {
                setLogo(null)
            }
            setIsLoading(false)
        } catch (error) {
            toast.errorRequest(error)
        }
    }

    useEffect(() => {
       handleFetchQuota(true)
       let intv = setInterval(() => {
            handleFetchQuota()
       }, 1000*15)
       return () => {
           clearInterval(intv)
       }
    }, [produk])
    
    return (
        <div className="mpk-flex align-center flex mod-quota">
            { isLoading ? (
                <div className="flex">
                    <LoaderInfo/>
                </div>
            ) : (
                <>
                    {logo ? (
                        <img 
                            className="produk-logo"
                            src={logo}
                        />
                    ) : (
                        <FontIcon
                            className="app-icon"
                            iconClassName="mdi mdi-postage-stamp"
                        />
                    )}
                    <div className="flex mpk-padding-N padding-right padding-left">
                        <div 
                            className={`application-name mpk-font size-N weight-M ${onClick ? 'mpk-link' : 'color-D2'}`}
                            onClick={onClick}
                        >
                            {produk.nama}
                        </div>
                        <div className="quota mpk-font weight-B size-XXL">
                            <TooltipHoverModeConfig 
                                enabled={true} 
                                delayTimeout={2} 
                                defaultDelay={2}
                            >
                                <Tooltipped
                                    id={`auto-positioning-above-produk`}
                                    tooltip={<span>{t.translate('sentence.quotaInfo')}</span>}
                                    defaultPosition="below"
                                >
                                    <span>{quotaData ? (quotaData.balance || 0) : 0}</span>
                                </Tooltipped>
                            </TooltipHoverModeConfig>
                            <FontIcon 
                                iconClassName="mdi mdi-reload"
                                className="mpk-font size-N mpk-link mpk-margin-S margin-left"
                                onClick={() => handleFetchQuota()}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default inject('authStore', 'envStore')(observer(Quota))
