import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import service from './Aplikasi.service'
import t from 'counterpart'
import ProdukService from '../Produk/Produk.service'
import utils from '../../services/utils'

const defaultData = () => ({
  produks:[]
});

const AplikasiForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [produkList, setProdukList] = useState([]);

  useEffect(() => {
    _getProduk()

    if(props.item) {
      var data = cloneDeep(props.item)
      if(!data.produks)data.produks = []
      data.produks.forEach(e => {
        utils.formSerialize(e);
      })
      setItem(data)

      console.log(data, 'asdsad')
      
    }
  }, [JSON.stringify(props.item)])

  async function _getProduk() {
    var res = await ProdukService.get({size: 10000})
    setProdukList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    ready && (
      <DataForm
        baseId="aplikasi-form"
        title="APLIKASI"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Nama",
            key: "nama",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Client ID",
            key: "clientId",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Client Secret",
            key: "clientSecret",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.LIST,
            label: 'Produk',
            key: 'produks',
            elementKey: 'produks',
            defaultData: {
              active: false,isDefault:false
            },
            definitions: [
              {
                inputType: DataForm.inputTypes.SELECT,
                label: 'Produk',
                key: 'produk__id',
                options: produkList,
                validation: 'required'
              },
              {
                inputType: DataForm.inputTypes.CHECKBOX,
                label: 'Aktif',
                key: 'active',
                theme: 'filled',
              },
              {
                inputType: DataForm.inputTypes.CHECKBOX,
                label: 'Default tampilan widget?',
                key: 'isDefault',
                theme: 'filled',
              },
              
            ]
          }
        ]}
        onBeforeChange={(key, value) => {
          return value;
        }}
        onSubmit={async (data, callback) => {
          var res = {};
          try {
            if(data.produks) {
              data.produks.forEach(e => {
                utils.formNormalize(e);
              })
            }
            if (!data.id) {
              res = await service.post(data);
            } else {
              res = await service.put(data);
            }
            callback(t.translate("sentence.dataTelahDiSimpan"), false);
          } catch (e) {
            callback(e, true, false);
          }
        }}
      />
    )
  );
};

export default AplikasiForm;
