import React, {useEffect, useState} from 'react'
import { FormWrapper, TabsWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Produk.service'
import Utils from '../../services/utils'
import AksesProdukTable from './AksesProduk.table'
import SatuanProdukTable from './SatuanProduk.table'
import SatuanService from '../Satuan/Satuan.service'
import ProdukLogoForm from './ProdukLogo.form'

const ProdukForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [isEdit, setIsEdit] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  let [satuanList, setSatuanList] = useState([]);

  // initial data
  useEffect(() => {
    
    initData()
  }, ['match.params.id'])

  async function initData() {
    await _getSatuan()
    if(match.params.id == 'baru') {
      setData({loading: false, content: {}})
      return true
    }
    const res = await service.getOne(match.params.id)
    res.data = Utils.formSerialize(res.data)
    setIsEdit(true)
    setData({loading: false, content:res.data})
  }


  async function _getSatuan() {
    var res = await SatuanService.get({size: 10000})
    setSatuanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  const handleTabChange = (index) => {
    setCurrentIndex(index)
  }

  const Form = (
    <FormWrapper
      noValidate
      showCommandbar={false}
      loading={data.loading}
      actions={[
      ]}
      backTo={`/internal/produk`}
      baseId="mod-form-sample"
      // title={t.translate('modules.produk.title')}
      hintShowIcon={false}
      defaultData={data.content}
      submitLabel="Submit"
      definitions={[
        // =====
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: "Nama",
          key: "nama",
          validation: "required",
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: "Kode",
          key: "kode",
          validation: "required",
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: "Logo",
          key: "logo",
          show: (d)=> match.params.id != 'baru'
        },
        {
          inputType: FormWrapper.inputTypes.SELECT,
          label: 'Satuan',
          key: 'satuan__id',
          options: satuanList,
          validation: 'required'
        },
        {
          inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
          label: "Harga",
          key: "harga",
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required|numeric',
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: "Keterangan",
          key: "keterangan",
        },

        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('sentence.dataTelahDiSimpan'), false)
          history.push('/internal/produk')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )

  return <TabsWrapper
    title="Produk"
    showCommandbar={true}
    onActiveIndexChange={handleTabChange}
    activeIndex={currentIndex}
    baseId="mod-details-sample"
    actions={[
      {name: 'Add', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/internal/produk')}
    ]}
    tabs={[
      {
        label: 'Produk',
        key: 'produk-form',
        render: Form
      },
      {
        label: 'Hak Akses',
        key: 'hak-akses-table',
        render: <AksesProdukTable produkId={match.params.id} />
      },
      ,
      {
        label: 'Logo',
        key: 'logo-form',
        render: <ProdukLogoForm produkId={match.params.id} initData={()=> initData()} logo={data.content.logo} />
      },
    ]
  }/>
}

export default inject('envStore')(observer(ProdukForm))
