import React from 'react'
import { TabsWrapper } from '../../libs/react-mpk/wrapper'
import { inject, observer } from 'mobx-react'
import TransactionList from './TransactionList'

const Transaction = ({ 
  className         = '',
  navigationStore, 
  envStore
}) => {
  return (
    // <TabsWrapper
    //   title="transaction-details"
    //   baseId="mod-details-sample"
    //   className={className}
    //   showCommandbar={false}
    //   tabs={['Finish', 'Process', 'Canceled', 'Expired'].map( key => ({
    //     label: `${key}(${10})`,
    //     key: key.toLowerCase(),
    //     render: (
    //       <TransactionList/>
    //     )
    //   }))}
    //   onChange={(tab, callback) => {
    //     navigationStore.redirectTo(`/${envStore.widget.active ? 'widget' : envStore.env.applicationType}/profile-details/transaction/${tab.key}`)
    //     callback()
    //   }}
    // />
    <TransactionList/>
  )
}

export default inject('navigationStore', 'envStore')(observer(Transaction))
