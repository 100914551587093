import React, { useEffect, useState } from "react";
import { FormWrapper, TabsWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./Produk.service";
import Utils from "../../services/utils";

const ProdukLogoForm = ({
  envStore,
  match,
  history,
  produkId,
  initData,
  logo,
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [data, setData] = useState({ loading: false, content: {} });
  const [imgBase64, setImgBase64] = useState(null);

  useEffect(() => {
    _getLogo(logo);
  }, [logo]);

  async function _getLogo(logo) {
    if (logo) {
      var res = await service.getImg(`/produk/${logo}/logo`);
      setImgBase64("data:;base64," + res);
    }
  }

  return (
    <>
      <div style={{width: '100%', marginBottom: 10}}>
        <img
          src={imgBase64}
          style={{ width: 200, height: "auto", margin: "0 auto", display: "block"}}
        />
      </div>
      <FormWrapper
        noValidate
        loading={data.loading}
        actions={[]}
        backTo={`/internal/produk`}
        baseId="mod-form-sample"
        // title={t.translate('modules.produk.title')}
        hintShowIcon={false}
        defaultData={data.content}
        submitLabel="Submit"
        showCommandbar={false}
        definitions={[
          // =====
          {
            inputType: FormWrapper.inputTypes.FILE_INPUT,
            label: "Logo",
            key: "logo",
            validation: "required",
          },
          // -----
        ]}
        onSubmit={async (data, callback) => {
          var res = {};
          try {
            var form = new FormData();
            form.set("logo", data.logo);
            res = await service.http.post(
              `/produk/${produkId}/upload_logo`,
              form
            );
            initData();
            callback(t.translate("sentence.dataTelahDiSimpan"), false);
          } catch (e) {
            callback(e, true, false);
          }
        }}
      />
    </>
  );
};

export default inject("envStore")(observer(ProdukLogoForm));
