import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './Rekonsiliasi.service'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import {
  TextField,
} from "@react-md/form";
import { Divider } from '@react-md/divider'

const _baseId = 'rekonsiliasi-detail-table'

const RekonsiliasiDetailTable = ({
  className       = '',
  showCommandbar  = true,
  modalStore,
  match
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [data, setData] = useState({})

  useEffect(()=> {
    init();
  }, [])

  async function init() {
    var res = await service.getOne(match.params.id);
    setData(res.data)
  }
  
  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title="Detail Rekonsiliasi"
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="createdDate"
        selectable={false}
        useQueryHistory={false}
        tableHeader={<div style={{width: '100%'}}>
          <Divider />
          <table style={{width: '100%'}}>
            <tr>
              <td>Dari Tanggal</td>
              <td>: { data.dariTanggal}</td>

              <td>Status</td>
              <td>: { data.status}</td>
            </tr>
            <tr>
              <td>Ke Tanggal</td>
              <td>: { data.keTanggal}</td>
              <td>Keterangan</td>
              <td>: { data.keterangan}</td>
            </tr>
            
          </table>
          <Divider />
          <br/>
        </div>}
        columns={[
          {
              label: 'Produk',
              key: 'produk',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.produk.nama)
          },
          {
            label: 'ReferenceNo',
            key: 'ReferenceNo',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.referenceNo)
          },
          {
            label: 'Status',
            key: 'status',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.status)
          },
          {
            label: 'Selisih',
            key: 'selisih',
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (item.selisih)
          },
          {
            label: 'unit',
            key: 'unit',
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (item.unit)
          },
          {
            label: 'Unit Tercatat',
            key: 'existingUnit',
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (item.existingUnit)
          },
          {
            label: 'Tipe Transaksi',
            key: 'tipeTransaksi',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.tipeTransaksi)
          },
          {
            label: 'Tipe Transaksi Tercatat',
            key: 'existingTipeTransaksi',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.existingTipeTransaksi)
          },
          {
            label: 'Username',
            key: 'username',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.username)
          },
          {
            label: 'Username Tercatat',
            key: 'existingUsername',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.existingUsername)
          },

        ]}
        actions={[
          // new TableWrapper.action(
          //   'Add', 
          //   'mdi mdi-plus', 
          //   () => {
          //     setSelectedItem(null)
          //     setShowForm(true)
          //   }, 
          //   true
          // )
        ]}
        itemActions={[
          // new TableWrapper.action(
          //   'Edit', 
          //   'mdi mdi-pencil', 
          //   (item) => {
          //     setSelectedItem(item)
          //     setShowForm(true)
          //   }, 
          //   true
          // ),
          // new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {
          //   modalStore.showConfirm({
          //     title       : t.translate('sentence.deleteDialogTitle'),
          //     children    : t.translate('sentence.deleteDialogMessege'),
          //     onSubmit    : async (callback) => {
          //       await service.delete(item.id)
          //       callback(t.translate('sentence.deleteDialogSuccess'))
          //       TableWrapper.reload(_baseId)
          //     }
          //   })
          // }, true)
        ]}
        onFetchData={ async query => {
          query['rekonsiliasiId.equals'] = match.params.id;
          return service.http.get(`${service.path}/${match.params.id}/detail`,query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      {/* <RekonsiliasiForm
        visible={showForm}
        onRequestClose={(data) => {
          TableWrapper.reload(_baseId)
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      /> */}
    </>
  )
}

export default inject('modalStore')(observer(RekonsiliasiDetailTable))
