import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { http, toast } from '../services';
import { merge } from 'lodash';
import qs from 'query-string';
import { LoaderInfo, Modal, Hint } from '../components';
import t from 'counterpart';
import mpkEnv from '../config/env'
import { themes } from '../config/constant';
import Script from 'react-load-script';


const wrapper =  (options={}) => WrappedComponent => {
  let opt = merge({
    url      : `/api/env`,
  }, options)

  function Init(props){
    const [isLoading, setIsLoading ] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null) 
    const requestTokenInterval = useRef()

    const setupApplication = () => {
      let { envStore, authStore } = props;
      return new Promise((resolve, reject) => {
        try {
          const { sameSite } = envStore.env.widgetGateway
          if(sameSite)
            authStore.setSameSite(sameSite)

          let parseWidget = window.location.pathname.match(/widget/g);
          let asWidget = parseWidget ? parseWidget[0] === 'widget' : false;

          const parsedUrl = qs.parseUrl(window.location.href);
          let { 
            accessToken, widgetClientId=null, accessKey, access_key, 
            origin=null, redirectUri=null, client=null, inline='0', clientId=null, 
            requestTokenPeriod=null, companyId=null, applicationType=null,
            ...otherParams
          } = parsedUrl.query;
        

          if(accessToken || accessKey) authStore.clearCookie();
          if(accessToken) authStore.setAccessToken(accessToken);
          if(accessKey || access_key) authStore.setAccessKey(accessKey || access_key);
          if(widgetClientId) http.setClientId(widgetClientId)
          if(clientId) http.setClientId(clientId)
          if(client) http.setClient(client)
          if(companyId) http.setCompanyId(companyId)
          if(applicationType) http.setApplicationType(applicationType)
          if(asWidget){
            if(accessToken && (!client && !widgetClientId)){
              reject(new Error('Client or widget client id is required for authorization with access token'))
            } else {
              envStore.setWidget({
                active: true,
                widgetClientId,
                client,
                parentOrigin: origin,
                redirectUri,
                inline: Number(inline) === 1,
                ...{accessToken, accessKey: accessKey || access_key},
                ...otherParams
              })
              setupListener()
              setupInterval(requestTokenPeriod)
            }
          }
          
          resolve();
        } catch (error) {
          reject(error)
        }
      })
    }



    const getEnvConfiguration = async () => {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await http.request({
            method: 'get',
            url: opt.url
          })

          let { name, publicName } = res.data.appInfo
          document.title = publicName || name

          // // SETUP HOST
          // const { widget } = props.envStore;
          // const { widgetGateway, apiGateway } = res.data;
          // let gateway = widget.active ? widgetGateway : apiGateway
          // http.setHost(gateway.host)
          // http.setBaseUrl(gateway.baseUrl)

          // if(!widget.active)
          //   http.setClientId(apiGateway.clientId);

          // SETUP LOCALE
          const lang = localStorage.getItem('lang')
          let { locale, ...env } = res.data;
          props.envStore.setEnv(env);

          const { channelName } = env.portal
          mpkEnv.setBrand(env.theme, channelName)
          if(env.tokenNames) {
            await props.authStore.setTokenNames(
              env.tokenNames.accessToken,
              env.tokenNames.refreshToken,
              env.tokenNames.guestToken,
              env.tokenNames.accessKey
            )
          }else if(env.theme === themes.SOBATPAJAK){
            await props.authStore.setTokenNames('SP_ACCESS_TOKEN', 'SP_REFRESH_TOKEN', 'SP_GUEST_TOKEN', 'SP_ACCESS_KEY')
          }

          locale.code = lang || locale.code;
          props.envStore.setLocale(locale);
          t.setLocale(locale.code);

          resolve(res.data);
        } catch (error) {
          reject(error);
        }
      })
    }

    const setupHost = () => {
      return new Promise(resolve => {
        // SETUP HOST
        const { widget } = props.envStore;
        const { widgetGateway, apiGateway } = props.envStore.env;
        let gateway = widget.active ? widgetGateway : apiGateway
        http.setHost(gateway.host)
        http.setBaseUrl(gateway.baseUrl)
        resolve()
      })
    }

    const setupInterval = (second) => {
      if(second){
        requestTokenInterval.current = setInterval(() => {
          props.envStore.widgetPost('request-access-token')
        }, second*1000)
      }
    }

    const setupListener = () => {
      let widget = window
      widget.addEventListener('message', handleMessage, false)
    }

    const handleMessage = (event) => {
      if(event.data){
        let { eventName } = event.data
        let chn = ['check-token', 'reload', 'update-access-token', 'reload'].indexOf(eventName) >= 0 ? 'sso-custom-message' : 'custom-message'
        console.log(eventName, chn)
        window.dispatchEvent(new CustomEvent(chn, {detail: event.data}))
      }
    }

    const handleWidgetWebchat = async () =>{
      let { widgetInterface } = props.envStore.env;
      if(widgetInterface.webchat){
        let i = 0
        let interval = setInterval( async () => {
          i++
          if(window.webChatClientPajakku && props.authStore.isLoggedIn){
            let accessToken = await props.authStore.getAccessToken(true);
            let params = {
              client: widgetInterface.webchat.clientId,
              fabBottom: '40px',
              fabRight: '24px',
              fabWidth: '54px',
              fabHeight: '54px',
              fabClassName: 'webchat-fab',
              applicationType: 'private',
              accessToken : accessToken,
              requestPeriod : 60
            }
            if(widgetInterface.webchat.layananId){
              params.layananId = widgetInterface.webchat.layananId
            }
            window.webChatClientPajakku.render(params, function(eventName, data){
              switch(eventName){
                case 'request-access-token':
                case 'error':
                    (async() => {
                      try {
                        let accessToken = await props.authStore.getAccessToken(true)
                        window.qmPajakku.post('update-access-token', {accessToken})
                      } catch (error) {
                        console.log(error)
                      }
                      
                    })()
                    break;
                default:
                  break;
            }
            })
            clearInterval(interval)
          }
          if(i > 4){
            clearInterval(interval)
          }
        }, 1000);
      }
    }

    useEffect(() => {
      if(isLoading){
        async function fetchData(){
          try {
            await getEnvConfiguration();
            await setupApplication();
            await setupHost();
            await handleWidgetWebchat()
            setIsLoading(false);
          } catch (error) {
            setErrorMessage(typeof error && error.message ?  error.message : error) 
            // window.alert('Server is not running correctly')
          }
        }

        fetchData();
      }

      return () => {
        window.removeEventListener('message', () => {}, null)
      }
    }, [])

    return errorMessage ? <Hint noBorder={true}>{errorMessage}</Hint> : (isLoading ? (
      <LoaderInfo className="mpk-initial-loader">
        Getting application configuration..
      </LoaderInfo>
    ) : (
      <>  
        <WrappedComponent {...props}/>
        <Modal.Confirm 
          {...props.modalStore.confirmation}
          onRequestClose={(isCancel) => props.modalStore.closeConfirm(isCancel)}  
        />
        <Modal.Info 
          {...props.modalStore.information}
          onRequestClose={() => {
            let {onRequestClose = () => {}} = props.modalStore.information
            props.modalStore.closeInfo()
            onRequestClose()
          }}
        />
      </>
    ))
  }

  return inject('envStore', 'navigationStore', 'modalStore', 'authStore')(observer(Init));
}

export default wrapper;