import React, { useEffect, useState, useRef } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import { toast } from '../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import service from './LogActivity.service'
import produkService from '../Produk/Produk.service'
import { Select } from 'react-md'
import t from 'counterpart'
import moment from 'moment'

const baseId = 'transaction-list'
const LogActivity = ({
    ...props
}) => {
    const [produkList, setProdukList] = useState([])
    const [selectedProdukId, setSelectedProdukId] = useState(null)
    const [ready, setReady] = useState(false)
    const selectedProdukIdRef = useRef()

    useEffect(async () => {
        try {
            let res = await produkService.getByClientId()
            if(res.data.length > 0){
                setProdukList(res.data)
                setSelectedProdukId(res.data[0].kode)
                selectedProdukIdRef.current = res.data[0].kode
                setReady(true)
            }else{
                throw new Error('No product available yet. Please contact administrator!')
            }
        } catch (error) {
            toast.errorRequest(error)
        }
    }, [])
    return ready && (
        <TableWrapper
            baseId={baseId}
            showFilter={!props.envStore.widget}
            showActionColumn={false}
            defaultSortBy="createdDate"
            tableHeader={(
                <div 
                    className="mpk-full full-width mpk-padding-N padding-left padding-right padding-top" 
                    style={{background: 'white'}}
                >
                    <Select
                        theme="underline"
                        label={t.translate('column.selectProduct')}
                        value={selectedProdukId}
                        options={produkList.map(d => ({
                            label: d.nama,
                            value: d.kode
                        }))}
                        onChange={e => {
                            setSelectedProdukId(e)
                            selectedProdukIdRef.current = e
                            TableWrapper.reload(baseId)
                        }}
                    />
                </div>
            )}
            columns={[
                {
                    label: t.translate('column.referenceNo'),
                    render: item => (item.referenceNo),
                    key: 'referenceNo',
                    sortable: true,
                },
                {
                    label: t.translate('column.tipeTransaksi'),
                    render: item => (item.tipeTransaksi),
                    key: 'tipeTransaksi',
                    sortable: true,
                },
                {
                    label: 'Unit',
                    render: item => (item.unit),
                    key: 'unit',
                    sortable: true,
                    searchable: false
                },
                {
                    label: t.translate('column.openBalance'),
                    render: item => (item.openBalance),
                    key: 'openBalance',
                    sortable: true,
                    searchable: false
                },
                {
                    label: t.translate('column.closeBalance'),
                    render: item => (item.closeBalance),
                    key: 'closeBalance',
                    sortable: true,
                    searchable: false
                },
                {
                    label: t.translate('column.createdDate'),
                    type: TableWrapper.dataTypes.DATE,
                    key: 'createdDate',
                    sortable: true,
                    render: item => (moment(item.createdDate).format('lll'))
                }
            ]}
            onFetchData={(query) => {
                console.log(selectedProdukIdRef.current)
                query['produkKode.equals'] = selectedProdukIdRef.current
                return service.get(query)
            }}
            {...props}
        />
    )
}

export default inject('envStore')(observer(LogActivity))
