import { Button } from '@react-md/button'
import { FontIcon } from '@react-md/icon'
import React, {useState, useEffect} from 'react'
import { LoadingButton, LoaderInfo } from '../../libs/react-mpk/components'
import { toast, http } from '../../libs/react-mpk/services'
import OrderForm from './OrderForm'
import { inject, observer } from 'mobx-react'
import Quota from './Quota'
import AllQuota from './AllQuota'
import './Profile.scss'

const Profile = ({
    fit         = true,
    asDetails   = false,
    ...props
}) => {
    const [showOrderForm, setShowOrderForm] = useState(false)
    const [userDefault, setUserDefault] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [showAllQuota, setShowAllQuota] = useState(false)
    const [tipeOrder, setTipeOrder] = useState('PREPAID')

    const handleFetchQuota = async () => {
        try {
            setIsLoading(true)
            let resDefault = await http.get(`/user-default-setting/get`)
            props.temporaryStore.setProperties('defaultProduk', resDefault.data.produk)
            props.temporaryStore.setProperties('defaultSetting', resDefault.data)
            setUserDefault(resDefault.data)
            setIsLoading(false)
        } catch (error) {
            toast.errorRequest(error)
        }
    }

    useEffect(() => {
        handleFetchQuota()
        const eventName = 'change-default-product'
        window.addEventListener(eventName, handleFetchQuota)

        return () => {
            window.removeEventListener(eventName, () => {}, false)
        }
    }, [])

    return (
        <>
            <div className={`mod-profile mpk-full full-width ${fit ? 'full-height' : ''} mpk-flex mpk-padding-N padding-all`}>
                <div className="mpk-flex align-center justify-between flex">
                    { props.temporaryStore.properties.backTo && (
                        <Button
                            buttonType="icon"
                            onClick={() => {
                                http.setClientId(null)
                                props.navigationStore.redirectTo(props.temporaryStore.properties.backTo)
                            }}
                        >
                            <FontIcon iconClassName="mdi mdi-arrow-left"/>
                        </Button>
                    )}
                    { isLoading ? (
                        <LoaderInfo/>
                    ) : (
                        <Quota 
                            produk={props.temporaryStore.properties.defaultProduk}
                            onClick={asDetails ? () => setShowAllQuota(true) : null}
                            onGetBalance={(balance) => {
                                setTipeOrder(balance.limit > 0 ? 'POSTPAID' : 'PREPAID')
                            }}
                        />
                    )}
                    {asDetails && props.envStore.env.theme !== '66cebbca847243f422fde45a47cc619b' && (props.envStore.widget.active || props.authStore.hasPermission('POST:/order')) && (
                        <LoadingButton
                            theme="primary"
                            themeType="contained"
                            iconClassName={tipeOrder==='POSTPAID' ? 'mdi mdi-check' : 'mdi mdi-cart'}
                            onClick={() => setShowOrderForm(true)}
                        >
                            {tipeOrder==='POSTPAID' ? 'Bayar' : 'Beli'}
                        </LoadingButton>
                    )}
                </div>
                {!asDetails && (
                    <Button
                        className="flex-none sm"
                        theme="primary"
                        themeType="outline"
                        buttonType="icon"
                        onClick={() => {
                            let { widget } = props.envStore
                            widget.post(widget.actions.OPEN_DETAILS.initial)
                        }}
                    >
                        <FontIcon iconClassName="mdi mdi-open-in-new"/>
                    </Button>
                )}
            </div>
            <OrderForm
                visible={showOrderForm}
                onRequestClose={() => setShowOrderForm(false)}
            />
            {asDetails && (
                <AllQuota
                    visible={showAllQuota}
                    onRequestClose={() => setShowAllQuota(false)}
                />
            )}
        </>
    )
}

export default inject('envStore', 'temporaryStore', 'navigationStore', 'authStore')(observer(Profile))
