import React, { useEffect, useState } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { sso } from '../libs/react-mpk/hocs';
import { PageWrapper } from '../libs/react-mpk/wrapper';
import { LoaderInfo } from '../libs/react-mpk/components';
import { 
	TableSample, FormSample, DetailsSample, DashboardSample, SatuanTable, ProdukTable, 
	ProdukForm, AplikasiTable, RekonsiliasiTable, RekonsiliasiDetail, KuotaTable 
} from '../modules';
import { ProfileDetails } from '../modules/Profile';
import ChooseApplication from '../modules/ChooseApplication'
import PaymentWidget from '../modules/PaymentWidget';
import { http } from '../libs/react-mpk/services';


const Internal = ({ history, ...props }) => {
	const [ready, setReady] = useState(false)
	const [allowed, setAllowed] = useState(false)
	useEffect(() => {
		let { user } = props.authStore
		let __allowed = props.envStore.env.constant.cmsRoles.indexOf(user.role.name) >= 0
		if(!__allowed) http.setClientId(props.envStore.env.defaultApplicationClientId)
		setAllowed(__allowed)
		setReady(true)
		setTimeout(() => {
			// if(!__allowed && window.location.pathname != '/internal/choose-application') {
				// props.navigationStore.redirectTo('/internal/choose-application')
			// }
			if(!__allowed){
				if(!window.location.pathname.match(new RegExp('/internal/profile-default/.*')))
					props.navigationStore.redirectTo('/internal/profile-details/transaction')
			}
		}, 1000)
	}, [])

	return ready ? (
		<PageWrapper
			sidebarCollapsible={true}
			inverseTheme={true}
			sidebarMenu={allowed ? null : []}
			useSidebar={allowed}
		>
			
			<Router history={history}>
				{ allowed ? (
					<Switch>
						<Route
							path='/internal/dashboard'
							render={props => (
								<div {...props} />
							)}
						/>
						<Route
							path='/internal/table'
							render={props => (
								<TableSample {...props} />
							)}
						/>
						<Route
							path='/internal/form'
							render={props => (
								<FormSample {...props} />
							)}
						/>
						<Route
							path='/internal/details/:tab'
							render={props => (
								<DetailsSample {...props} />
							)}
						/>
						<Route
							path='/internal/satuan'
							render={props => (
								<SatuanTable {...props} />
							)}
						/>
						<Route
							path='/internal/produk/:id'
							render={props => (
								<ProdukForm {...props} />
							)}
						/>
						<Route
							path='/internal/produk'
							render={props => (
								<ProdukTable {...props} />
							)}
						/>
						<Route
							path='/internal/aplikasi'
							render={props => (
								<AplikasiTable {...props} />
							)}
						/>
						<Route
							path='/internal/rekonsiliasi/:id'
							render={props => (
								<RekonsiliasiDetail {...props} />
							)}
						/>
						<Route
							path='/internal/rekonsiliasi'
							render={props => (
								<RekonsiliasiTable {...props} />
							)}
						/>
						<Route
							path='/internal/kuota'
							render={props => (
								<KuotaTable {...props}/>
							)}
						/>
					</Switch>
				) : (
					<Switch>
						{/* <Route
							path="/internal/choose-application"
							render={props => (
								<ChooseApplication {...props}/>
							)}
						/> */}
						<Route
							path="/internal/profile-details/:tab"
							render={props => (
								<ProfileDetails {...props}/>
							)}
						/>
					</Switch>
				)}
			</Router>
			<PaymentWidget/>
		</PageWrapper>
	) : <LoaderInfo>checking permission...</LoaderInfo>
}

export default sso({
	basePath: '/internal'
})(Internal);