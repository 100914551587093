import React, {useEffect, useState} from 'react'
import { Modal } from '../../libs/react-mpk/components'
import { http, toast } from '../../libs/react-mpk/services'
import produkService from '../Produk/Produk.service'
import { Button } from 'react-md'
import Quota from './Quota'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'

const AllQuota = ({
    visible,
    onRequestClose,
    ...props
}) => {
    const [ produkList, setProdukList ] = useState([])
    const [ready, setReady] = useState(false)
    let { defaultProduk } = props.temporaryStore.properties

    useEffect(async() => {
        try {
            let res = await produkService.getByClientId()
            setProdukList(res.data)
            setReady(true)
        } catch (error) {
            toast.errorRequest(error)
        }
    }, [])

    return ready && (
        <Modal
            baseId={'mod-profile-all-balance'}
            visible={visible}
            onRequestClose={onRequestClose}
            dialogStyle={{
                width: '100%',
                maxWidth: 400
            }}
            footer={
                <Button
                    themeType="outline"
                    onClick={onRequestClose}
                >
                    {t.translate('column.close')}
                </Button>
            }
        >
            <div className="mpk-full full-width">
                {produkList.map(d => (
                    <div 
                        className="mpk-flex align-center mpk-border thin solid dark border-bottom mpk-padding-S padding-bottom padding-top"
                        key={d.id}
                    >
                        <Quota 
                            produk={d}
                        />
                        <div>
                            <Button
                                theme="primary"
                                themeType="outline"
                                disabled={defaultProduk && (defaultProduk.id === d.id)}
                                onClick={async () => {
                                    props.temporaryStore.setProperties('defaultProduk', d)
                                    try {
                                        const { widget } = props.envStore
                                        await http.post(`/user-default-setting`, {
                                            aplikasi: props.temporaryStore.properties.defaultSetting.aplikasi,
                                            produk: d,
                                            username: props.authStore.user.username
                                        })
                                        if(widget.active){
                                            widget.post('change-default-product', d)
                                        }
                                    } catch (error) {
                                        toast.errorRequest(error)
                                    }
                                }}
                            >
                                Default
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    )
}

export default inject('temporaryStore', 'authStore', 'envStore')(observer(AllQuota))
