import React from 'react'
import { CompanyCard, Link, Masonry } from '../../libs/react-mpk/components'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import httpService from '../../libs/react-mpk/services/http.service'
import aplikasiService from '../Aplikasi/Aplikasi.service'
import { http } from '../../libs/react-mpk/services'
import { FontIcon } from 'react-md'

const ChooseApplication = ({ authStore, envStore, navigationStore, temporaryStore }) => {
  return (
    <TableWrapper
      title={t.translate('column.chooseApplication')}
      baseId="mod-choose-application"
      useFilter={true}
      columns={[
          {
                label: t.translate('column.name'),
                key: 'nama',
                searchable: true,
                show: false
          }
      ]}
      render={(data) => (
        <div className="mpk-padding-N padding-all mpk-animation slide-in mpk-full full-width">
          {data.map(d => (
            <div
                key={`application-${d.id}`}
                className="mpk-padding-N padding-all mpk-paper mpk-border thin solid dark border-all mpk-margin-XS margin-bottom mpk-full full-width mpk-animation slide-in mpk-flex justify-between align-center"
                onClick={() => {
                    http.setClientId(d.clientId)
                    temporaryStore.setProperties('backTo', '/internal/choose-application')
                    navigationStore.redirectTo(`/internal/profile-details/transaction`)
                }}
            >
                <div className="mpk-font size-M weight-B mpk-link">{d.nama}</div>
                <FontIcon iconClassName="mdi mdi-open-in-new"/>
            </div>
          ))}
        </div>
      )}
      onFetchData={(query) => (aplikasiService.get(query))}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore', 'temporaryStore')(observer(ChooseApplication))
