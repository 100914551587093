import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import service from './Rekonsiliasi.service'
import t from 'counterpart'
import aplikasiService from '../Aplikasi/Aplikasi.service'

const defaultData = () => ({keterangan: ''});

const RekonsiliasiForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [aplikasiList, setAplikasiList] = useState([]);

  useEffect(() => {
    _getAplikasi()
  }, [props.item])

  async function _getAplikasi() {
    var res = await aplikasiService.get({size: 10000})
    setAplikasiList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    ready && (
      <DataForm
        baseId="rekonsiliasi-form"
        title="REKONSILIASI"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.SELECT,
            label: 'Aplikasi',
            key: 'aplikasi__id',
            options: aplikasiList,
            validation: 'required'
          },
          {
            inputType: DataForm.inputTypes.DATEPICKER,
            label: "Dari Tanggal",
            key: "dariTanggal",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.DATEPICKER,
            label: "Ke Tanggal",
            key: "keTanggal",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Keterangan",
            key: "keterangan",
          },
          {
            inputType: DataForm.inputTypes.DIVIDER,
            label: "IMPORT FILE",
          },
          {
            inputType: DataForm.inputTypes.FILE_INPUT,
            label: "File",
            key: "file",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: 'Delimiter',
            key: 'delimiter',
            options: [{
              value: ',',
              label: 'Koma'
            },{
              value: ';',
              label: 'Titik Koma'
            }],
            validation: 'required'
          },
        ]}
        onBeforeChange={(key, value) => {
          return value;
        }}
        onSubmit={async (data, callback) => {
          var res = {};
          try {
            var formData = new FormData();
            formData.append('aplikasiId', data.aplikasi__id)
            formData.append('file', data.file)
            formData.append('delimiter', data.delimiter)
            formData.append('dariTanggal', data.dariTanggal)
            formData.append('keTanggal', data.keTanggal)
            formData.append('keterangan', data.keterangan)
            res = await service.http.post(service.path+"/import", formData);
            callback(t.translate("sentence.dataTelahDiSimpan"), false);
          } catch (e) {
            callback(e, true, false);
          }
        }}
      />
    )
  );
};

export default RekonsiliasiForm;
