import React from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import { inject, observer } from 'mobx-react'
import service from './Transaction.service'
import t from 'counterpart'
import numeral from 'numeral'
import moment from 'moment'

const baseId = 'mod-transaction-list'
const TransactionList = ({
    ...props
}) => {
    return (
        <TableWrapper
            baseId={baseId}
            showFilter={!props.envStore.widget.active}
            showCommandbar={false}
            showActionColumn={false}
            defaultSortBy="createdDate"
            columns={[
                {
                    label: t.translate('column.orderNo'),
                    render: item => {
                        const isActivePayment = ['waiting payment', 'order created'].indexOf(item.status.toLowerCase()) >= 0 
                        return (
                            <div 
                                className={isActivePayment ? 'mpk-link' : ''}
                                onClick={isActivePayment ? () => {
                                    window.dispatchEvent(new CustomEvent('payment-widget-message', {detail: {
                                        visible: true,
                                        order: item,
                                        tableBaseId: baseId
                                    }}))
                                } : null}
                            >
                                {item.orderNo}
                            </div>
                        )
                    },
                    key: 'orderNo',
                    sortable: true,
                    searchable: true
                },
                {
                    label: t.translate('column.amountTotal'),
                    key:'amountTotal',
                    searchable: false,
                    sortable: true,
                    render: item => (
                        <div className="mpk-align align-right">
                            {numeral(item.amountTotal).format('0,0')}
                        </div>
                    )
                },
                {
                    label: 'Status',
                    key:'status',
                    searchable: true,
                    sortable: true,
                    render: item => {
                        let status = TableWrapper.Status.types.IDLE
                        switch(item.status.toLowerCase()){
                            case 'expired':
                                status = TableWrapper.Status.types.ERROR
                                break
                            case 'paid':
                                status = TableWrapper.Status.types.SUCCESS
                                break
                            case 'waiting payment':
                                status = TableWrapper.Status.types.PROGRESS
                                break
                            case 'order created':
                            case 'canceled':
                            default:
                                status = TableWrapper.Status.types.IDLE
                        }
                        return (
                            <TableWrapper.Status 
                                type={item.boolean 
                                    ? TableWrapper.Status.types.SUCCESS
                                    : TableWrapper.Status.types.ERROR
                                }
                            >
                                {item.status}
                            </TableWrapper.Status>
                      )
                }},
                {
                    label: t.translate('column.createdDate'),
                    type: TableWrapper.dataTypes.DATE,
                    sortable: true,
                    key: 'createdDate',
                    render: item => (moment(item.createdDate).format('lll'))
                }
            ]}
            onFetchData={ async query => {
                return service.get(query)
            }}
            {...props}
        />
    )
}

export default inject('envStore')(observer(TransactionList))
