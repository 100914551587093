import React, {useState} from 'react'
import { useParams } from 'react-router-dom'
import { Commandbar, DividerLabel, Flex } from '../components'
import { TabsManager, Tabs, TabPanels, TabPanel, FontIcon, TabConfig, Button } from 'react-md'
import { find } from 'lodash'
import './TabsWrapper.scss'

const TabsWrapper = ({
  title                 = null,
  baseId                = 'mpk-table-wrapper',
  backTo                = null,
  className             = '',
  tabPanelClassname     = '',
  actions               = null,
  showCommandbar        = true,
  tabs                  = [],
  onChange              = null,
  commandbar            = {},
  showIndex             = false,
  showLabel             = true,
  defaultAsVerticalTabs = false,
  disabledToggleTabsOrientation   = true,
  disabledTabsScrollControl   = true,
  scrollGap             = 160,
  ...props
}) => {
  const { tab } = useParams()
  const tabList = tabs.filter(d => d.show === undefined ? true : d.show);
  const tabItem = find(tabList, {key: tab})
  const [currentIndex, setCurrentIndex] = useState(tabItem ? tabs.indexOf(tabItem) : 0)
  const [asVerticalTabs, setAsVerticalTabs] = useState(defaultAsVerticalTabs)
  const handleTabChange = (index) => {
    if(onChange){
      onChange(tabs[index], (newIndex) => {
        setCurrentIndex(newIndex || index)
      })
    } else {
      setCurrentIndex(index)
    }
  }

  const onNextTab = () => {
    if(currentIndex < tabList.length - 1) setCurrentIndex(currentIndex+1)
  }

  const onPrevTab = () => {
    if(currentIndex > 0) setCurrentIndex(currentIndex-1)
  }

  const goToIndex = index => {
    setCurrentIndex(index)
  }

  const handleScrollTabs = (toRight=false) => {
    let el = document.getElementById(baseId)
    el.scrollTo(el.scrollLeft + (scrollGap*(toRight ? 1 : -1)), 0)
  }

  return (
    <Flex 
      className={`mpk-tabs-wrapper mpk-details-wrapper mpk-full full-height ${className} ${asVerticalTabs ? 'vertical' : ''} ${!disabledTabsScrollControl ? 'show-scroll-control' : ''}`}
      direction={Flex.properties.direction.COLUMN}
      fit
    >
      { showCommandbar && (
        <Commandbar
          title={title}
          className="flex-none mpk-border solid dark border-bottom"
          actions={actions}
          backTo={backTo}
          {...{
            ...commandbar,
            rightCorner:(
              <div className="mpk-flex align-center">
                {!disabledToggleTabsOrientation && (
                  <Button 
                    buttonType="icon"
                    className={commandbar.rightCorner ? 'mpk-margin-N margin-right' : ''}
                    onClick={() => setAsVerticalTabs(!asVerticalTabs)}
                  >
                    <FontIcon iconClassName={asVerticalTabs ? "mdi mdi-dots-horizontal-circle-outline" : "mdi mdi-dots-vertical-circle-outline"}/>
                  </Button>
                )}
                {commandbar.rightCorner}
              </div>
            )
          }}
        />
      )}
      <Flex 
        fit
        direction={asVerticalTabs ? Flex.properties.direction.ROW : Flex.properties.direction.COLUMN}
        className="mpk-full full-width mpk-position position-relative "
      >
        <TabsManager 
          tabsId={baseId}
          tabs={tabList.map((d, i) => {
            let label = `${showIndex && !asVerticalTabs ? `${i+1}. ` : ''}${d.label}`
            return d.iconClassName 
              ? {
                  children: label, 
                  icon: (
                    <FontIcon 
                      iconClassName={d.iconClassName} 
                      style={d.iconStyle}
                    />
                  ), 
                  iconAfter: !asVerticalTabs
                }
              : {children: label}
          })}
          onActiveIndexChange={handleTabChange}
          activeIndex={currentIndex}
          {...props}
        >
          <Tabs/>
          {!disabledTabsScrollControl && !asVerticalTabs && (
            <>
              <Button
                themeType='primary'
                buttonType='icon'
                className='scroll-ctrl left mpk-border thin solid dark border-right'
                onClick={() => handleScrollTabs()}
              >
                <FontIcon iconClassName='mdi mdi-chevron-left'/>
              </Button>
              <Button
                themeType='primary'
                buttonType='icon'
                className='scroll-ctrl right mpk-border thin solid dark border-left'
                onClick={() => handleScrollTabs(true)}
              >
                <FontIcon iconClassName='mdi mdi-chevron-right'/>
              </Button>
            </>
          )}
          <div className={`flex mpk-flex direction-column mpk-full ${asVerticalTabs ? 'full-height' : 'full-width'}`}>
            {asVerticalTabs && showLabel && <DividerLabel className="mpk-padding-N padding-top padding-left padding-right">{tabs[currentIndex].label}</DividerLabel>}
            <TabPanels
              className={`flex mpk-full full-width ${tabPanelClassname}`}
              style={{height: 'auto', flex: 1}}
            >
              {tabList.map(tab => (
                <TabPanel
                  key={`${baseId}-tab-${tab.key}`}
                  style={{
                    willChange: 'unset'
                  }}
                  onClick={() => {}}
                >
                  {typeof tab.render === 'function' ? tab.render({onNextTab, onPrevTab, goToIndex}) : tab.render}
                </TabPanel>
              ))}
            </TabPanels>
          </div>
        </TabsManager>
      </Flex>
    </Flex>
  )
}

export default TabsWrapper
