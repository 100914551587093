import React, {useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './Kuota.service'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { DataForm } from "../../libs/react-mpk/components";

const _baseId = 'satuan-table'

const KuotaTable = ({
  className       = '',
  showCommandbar  = true,
  modalStore
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  
  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={t.translate('modules.quota.title')}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultCollapse={true}
        // useCriteria={false}
        selectable={false}
        useQueryHistory={false}
        extraFilter={<div>Test</div>}
        multiColumnFilter={true}
        // extraFilter={<div></div>}
        columns={[
          {
            label: 'Produk',
            key: 'produkNama',
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.produk?item.produk.nama:'')
          },
          {
            label: 'Reference No',
            key: 'referenceNo',
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.referenceNo)
          },
          {
            label: 'Order No',
            key: 'orderNo',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.order?item.order.orderNo:'')
          },
          {
            label: 'Tipe Transaksi',
            key: 'tipeTransaksi',
            sortable: true,
            definition:{
              inputType: TableWrapper.inputTypes.INPUT,
              defaultValue: ''
            },
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.tipeTransaksi)
          },
          {
            label: 'Unit',
            key: 'unit',
            sortable: true,
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (item.unit)
          },
          {
            label: 'Open Balance',
            key: 'openBalance',
            sortable: true,
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (item.openBalance)
          },
          {
            label: 'Close Balance',
            key: 'closeBalance',
            sortable: true,
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (item.closeBalance)
          },
          {
            label: 'Tanggal Dibuat',
            key: 'createdDate',
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            definition:{
              inputType: TableWrapper.inputTypes.DATEPICKER,
              defaultValue: ''
            },
            render: item => (item.createdDate)
          },
          {
            label: 'Dibuat Oleh',
            key: 'createdBy',
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdBy)
          },
          

        ]}
        actions={[
          new TableWrapper.action(
            'Add', 
            'mdi mdi-plus', 
            () => {
              setSelectedItem(null)
              setShowForm(true)
            }, 
            true
          )
        ]}
        itemActions={[
          new TableWrapper.action(
            'Edit', 
            'mdi mdi-pencil', 
            (item) => {
              setSelectedItem(item)
              setShowForm(true)
            }, 
            true
          ),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {
            modalStore.showConfirm({
              title       : t.translate('sentence.deleteDialogTitle'),
              children    : t.translate('sentence.deleteDialogMessege'),
              onSubmit    : async (callback) => {
                await service.delete(item.id)
                callback(t.translate('sentence.deleteDialogSuccess'))
                TableWrapper.reload(_baseId)
              }
            })
          }, true)
        ]}
        onFetchData={ async query => {
          return service.get(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      {/* <SatuanForm
        visible={showForm}
        onRequestClose={(data) => {
          TableWrapper.reload(_baseId)
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      /> */}
    </>
  )
}

export default inject('modalStore')(observer(KuotaTable))
